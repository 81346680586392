import React from 'react';
import VersionWidget from './VersionWidget';

class Versions extends React.Component {
    render(){
        const { filters } = this.props;
        let versions = [];
        if(this.props.versions) {
            versions = this.props.versions
                .filter(i => filters.fuelType.length === 0 || filters.fuelType.includes(i.calculatedFuelType))
                .filter(i => filters.gearboxType.length === 0 || filters.gearboxType.includes(i.gearboxType))
                .filter(i => filters.traction.length === 0 || filters.traction.includes(i.traction))
                .filter(i => filters.engine.length === 0 || filters.engine.includes(i.engine.liters))
                .filter(i => filters.trim.length === 0 || filters.trim.includes(i.trimName))
                .filter(i => {
                        if(filters.version.length === 0) return true;
                        const regexp = filters.version.join('|');
                        const res = i.versionName.match(new RegExp(regexp,'ig'))
                        if(!res) console.log('h',regexp, i.versionName);
                        return res? true : false;
                    }
                )

            this.props.versions.forEach(i => {
                if(!versions.includes(i)) {
                    //console.log('left out',i.versionName)
                }
            })
        }
        return(
            <div className="Versions-list" id="Versions-list">
                <header>Versioni: {versions? versions.length + ' / ' + (this.props.versions.length || '--') : ''}</header>
                {
                    versions && versions.length ?
                        versions.map((it,key) => {
                            it.link = this.props.link;
                            return <VersionWidget 
                                click={this.props.click} 
                                selectVersion={this.props.selectVersion} 
                                key={it.versionId} 
                                item={it} 
                                index={key} 
                                configure={this.props.configure}
                                link={this.props.link}
                            />
                        })
                        : 
                        <div>
                            <h2>Non ci sono versioni con questa combinazione di filtri</h2>
                        </div>
                    }
            </div>
            
        )
    }
}

export default Versions;