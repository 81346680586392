import Actions from '../actions';

const initialState = {
    data: []
};
  
export default function carsReducer(state = initialState, action) {
    switch(action.type) {
        case Actions.OFFERS_COMPLETED:
            return {
                data: action.payload
            }
        default: break;
    }
    return state;
    
}