import React from 'react';
import iconNoleggio from '../../assets/imgs/noleggio.png';
/* import iconNuovo from '../../assets/imgs/nuovo.png';
import iconUsato from '../../assets/imgs/usato.png';
import iconKm0 from '../../assets/imgs/km0.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons' */

import './ModalRequest.css';
import '../FormItems/FormItems.css';
import MiniOffer from '../CarModel/MiniOffer';


class ModalRequestOfferDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelected: 'Noleggio',
            selectedOffers: ['Noleggio'],
            suggested: {},
            quotes: props.quotes || {
                Noleggio: { costo_iniziale: 1500, sel_km_annuali: 16000, sel_durata: 48 },
                Acquisto: {}
            }
        }
        this.click = this.click.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    click(e) {
        e.preventDefault();
        this.setState({ tabSelected: e.target.id });
    }

    toggleSuggested(e) {
        const { target } = e;
        if(!target) return;
        console.log('toggleSuggested', target.id)
        e.preventDefault();
        const id = target.id;
        if (this.state.suggested[id]) {
            this.setState(old => {
                delete old.suggested[id];
                console.log('deleting',id)
                return old;
            })
        } else {
            this.setState(old => {
                old.suggested[id] = true;
                console.log('adding',id, '-',old.suggested)
                return old;
            })
        }
        if(this.props.suggestedUpdate) this.props.suggestedUpdate(id)

    }

    toggle(x) {
        this.setState(old => {
            if (old.selectedOffers.includes(x)) {
                old.selectedOffers.splice(old.selectedOffers.indexOf(x), 1);
                if (!old.selectedOffers.includes("Noleggio")) {
                    old.quotes.Noleggio = {}
                }
                if (!old.selectedOffers.includes("Nuovo") && !old.selectedOffers.includes("Usato") && !old.selectedOffers.includes("Km0")) {
                    old.quotes.Acquisto = {}
                }
            } else {
                old.selectedOffers.push(x);
                if (x !== "Noleggio") {
                    if (!old.quotes.Acquisto.sel_durata) {
                        old.quotes.Acquisto = { costo_iniziale: 1500, sel_durata: 48 }
                    }
                } else {
                    if (!old.quotes.Noleggio.sel_durata) {
                        old.quotes.Noleggio = { costo_iniziale: 1500, sel_km_annuali: 12000, sel_durata: 36 }
                    }
                }
            }
            if (this.props.selectedOffers) this.props.selectedOffers('selectedOffers', old.selectedOffers);
            return old;
        });
    }

    change(e) {
        const { name, value } = e.target;
        if (!name || !value) return;
        this.setState(old => {
            old.quotes[old.tabSelected][name] = value;
            if (this.props.selectedOffers) this.props.selectedOffers('quotes', old.quotes);
            return old;
        })
    }

    render() {
        const { /* tabSelected ,*/ selectedOffers } = this.state;
        const offers = this.props.offers || [];
        //console.log(tabSelected);
        return (
            <div className="ModalRequest-container">
                <div style={{ boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)', borderRadius: 4, padding: 20 }}>
                    {/* <div style={{ display: 'flex' }}>
                        <div className={"noselect tabBlock noleggio" + (tabSelected === 'Noleggio' ? ' selected' : '')} onClick={this.click} id="Noleggio">
                            Noleggio
                            {selectedOffers.includes("Noleggio") && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 5 }} />}
                        </div>
                        <div className={"noselect tabBlock acquisto" + (tabSelected === 'Acquisto' ? ' selected' : '')} onClick={this.click} id="Acquisto">
                            Acquisto
                            {selectedOffers.some(i => ["Nuovo", "Usato", "Km0"].includes(i)) && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 5 }} />}
                        </div>
                    </div> */}

                    {
                        //tabSelected === 'Noleggio' ?
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <MiniCarSelector
                                        car={this.props.model.icons[0].icon100}
                                        type="Noleggio"
                                        icon={iconNoleggio}
                                        subtitle="da €250,00/mese i.e."
                                        selected={selectedOffers.includes("Noleggio")}
                                        //click={e => this.toggle("Noleggio")}
                                        color={[230,26,108,0.25]}
                                    />
                                </div>
                                {/* {selectedOffers.includes("Noleggio") &&  */}
                                <ListNoleggio quote={this.state.quotes} change={this.change.bind(this)} />
                                
                                <div style={{ height: 20 }}></div>
                                {
                                    (offers && offers.length && offers.find(i => i.modelUrlCode === this.props.model.modelUrlCode)) ? 
                                        <h3 style={{ textAlign: 'left' }}>Offerte già negoziate</h3>
                                        : null
                                }
                                {
                                    (offers && offers.length) ?
                                    <div className="MiniOffer">
                                        {
                                            offers && offers.filter(i => {
                                                if(i.modelUrlCode === this.props.model.modelUrlCode) return true;
                                                const test = new RegExp(this.props.model.makeName.toUpperCase() + ' ' + this.props.model.modelName.toUpperCase(),'ig')
                                                return i.nome.match(test);
                                            }).slice(0,2)
                                            .map((off, i) => {
                                                console.log('offerta', off);
                                                const name = off.nome.slice(off.nome.indexOf(this.props.model.modelName.toUpperCase()) + this.props.model.modelName.length,off.nome.indexOf("("))
                                                return (
                                                    <MiniOffer
                                                        key={off.id}
                                                        name={this.props.model.submodelCommercialName}
                                                        version={name}
                                                        off={{...off}}
                                                        car={this.props.model.icons[0].icon100}
                                                        type="Noleggio"
                                                        icon={iconNoleggio}
                                                        selected={this.state.suggested[off.id]}
                                                        toggle={this.toggleSuggested.bind(this)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                            /*:
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <MiniCarSelector
                                        car={this.props.model.icons[0].icon100}
                                        type="Nuovo"
                                        icon={iconNuovo}
                                        subtitle="da €250,00/mese i.e."
                                        selected={selectedOffers.includes("Nuovo")}
                                        click={e => this.toggle("Nuovo")}
                                        color={[23,48,85,0.25]}
                                    />
                                    <MiniCarSelector
                                        car={this.props.model.icons[0].icon100}
                                        type="Usato"
                                        icon={iconUsato}
                                        subtitle="da €250,00/mese i.e."
                                        selected={selectedOffers.includes("Usato")}
                                        click={e => this.toggle("Usato")}
                                        color={[23,48,85,0.25]}
                                    />
                                    <MiniCarSelector
                                        car={this.props.model.icons[0].icon100}
                                        type="Km0"
                                        icon={iconKm0}
                                        subtitle="da €250,00/mese i.e."
                                        selected={selectedOffers.includes("Km0")}
                                        click={e => this.toggle("Km0")}
                                        color={[23,48,85,0.25]}
                                    />
                                </div>
                                {selectedOffers.some(i => ["Nuovo", "Usato", "Km0"].includes(i)) ? <ListAcquisto quote={this.state.quotes} change={this.change.bind(this)} />:null}
                            </div>*/
                    }
                </div>
            </div>
        );
    }
};



export const CustomCheckbox = ({ selected }) => {
    return (
        <div className="emptyCheck">
            {selected && <div className="selectedCheck"></div>}
        </div>
    );
}

const MiniCarSelector = ({ car, type, icon, subtitle, selected, click, color }) => {
    const [r,g,b,a] = color;
    const color1 = `rgba(${r},${g},${b},${a})`;
    const color2 = `rgba(${r},${g},${b},0.1)`;
    const gradient = `linear-gradient(${color1},${color2})`;
    
    return (
        <div className="miniCarSelector noselect" onClick={click} style={{backgroundImage: gradient}}>
            <CustomCheckbox selected={selected} name={type} />
            <span className="noselect" style={{ verticalAlign: 'top', lineHeight: '32px' }}>{type}</span>
            <div style={{ textAlign: 'center', position: 'relative' }}>
                <img className="noselect miniCarIcon" src={icon} alt={type} />
                <img className="noselect miniCarImg" src={car} alt={type} />
            </div>
        </div>
    );
};

const ListNoleggio = ({ change, quote }) => {
    if (!quote) quote = { Noleggio: {} }
    return (
        <div className="miniFormRequest" style={{backgroundColor: 'rgba(230,26,108,0.1)', padding: 10}}>
            <h4>Personalizza offerta di noleggio</h4>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: 10 }}>
                <div>
                    <label>Anticipo</label>
                    <input name="costo_iniziale" type="number" placeholder="1500" onChange={change}
                        value={(quote.Noleggio && quote.Noleggio.costo_iniziale) || null} />
                </div>
                <div>
                    <label>Km annuali</label>
                    <input name="sel_km_annuali" type="number" placeholder="12000" onChange={change}
                        value={(quote.Noleggio && quote.Noleggio.sel_km_annuali) || null} />
                </div>
                <div>
                    <label>Durata</label>
                    <select name="sel_durata" onChange={change} defaultValue={quote.Noleggio.sel_durata || null}>
                        <option value="12">12 mesi</option>
                        <option value="24">24 mesi</option>
                        <option value="36">36 mesi</option>
                        <option value="48">48 mesi</option>
                        <option value="60">60 mesi</option>
                        <option value="72">72 mesi</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

/* const ListAcquisto = ({ change, quote }) => {
    if (!quote) quote = { Acquisto: { costo_iniziale: 1500, sel_durata: 36 } }
    const anticipo = (quote.Acquisto && quote.Acquisto.costo_iniziale) || 1500;
    return (
        <div className="miniFormRequest" style={{backgroundColor: 'rgba(23,48,85,0.1)', padding: 10}}>
            <h4>Personalizza offerta di acquisto</h4>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: 10 }}>
                <div>
                    <label>Anticipo</label>
                    <input name="costo_iniziale" type="number" placeholder="1500" onChange={change}
                        value={anticipo} />
                </div>
                <div>
                    <label>Durata</label>
                    <select name="sel_durata" onChange={change} defaultValue={quote.Acquisto.sel_durata || 36}>
                        <option value="12">12 mesi</option>
                        <option value="24">24 mesi</option>
                        <option value="36">36 mesi</option>
                        <option value="48">48 mesi</option>
                        <option value="60">60 mesi</option>
                        <option value="72">72 mesi</option>
                    </select>
                </div>
            </div>
        </div>
    );
} */

export default ModalRequestOfferDetails;