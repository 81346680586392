import axios from 'axios';
import { throwError } from 'rxjs';
import lz from 'lz-string';

import config from '../Appconfig';

const API_FORM_SEND = "https://gestionale.urcar.it/offers_website_elabform.php";
const API_ALLDB_URL = "alldb/e95d52e5f87d6967a8a79ea105aa0782044ed34442982973e1aec4d9b300";

// eslint-disable-next-line
let API_HOST;
// eslint-disable-next-line
let API_ENDPOINT;
let API_ENDPOINT_V2;

switch(config.proxy) {
  default:
  case 'dev':
    API_HOST = 'http://localhost:39010/';
    break;
  case 'live':
    API_HOST = 'https://api.urcar.it/';
    break;
}

if(config.source === 'lcv') {
  API_ENDPOINT = API_HOST + "lcv/";
  API_ENDPOINT_V2 = API_HOST + "lcv/";
} else {
  API_ENDPOINT = API_HOST + "vx/";
  API_ENDPOINT_V2 = API_HOST + "v2/";
}
const API_COMUNI = "https://gestionale.urcar.it/GET/elenco_comuni.php";
class Api {
    constructor() {
      this.headers = {};
    }
    
    async get (endpoint) {
      return axios.get(API_ENDPOINT + endpoint)
          .then(async response => {
              const sizer = new Buffer(JSON.stringify(response.data));
              console.log('received get',(sizer.byteLength/1024).toFixed(3),'kb',response);
              return response;
          })
          .catch(err => {
            console.error(err);
            throwError(err)
          });
    }

    async post (endpoint, data) {
        return axios.post(API_ENDPOINT + endpoint, data)
            .then(response => {
                console.log('received post',response);
                const sizer = new Buffer(JSON.stringify(response.data));
                console.log('size',(sizer.byteLength/1024).toFixed(3),'kb');
                return response;
            })
            .catch(err => throwError(err));
    }

    async loadAll(){
      const cached = await localStorage.getItem('loadall');
      if(cached) return JSON.parse(lz.decompressFromUTF16(cached));
              
      return await this.get(API_ALLDB_URL)
          .then(response => {
            const db = this.prepareDatabase(response.data || []);
            localStorage.setItem('loadall',lz.compressToUTF16(JSON.stringify(db)));
            return db;
          })
          .catch(err => throwError(err));
    }

    async loadComuni(){
      return axios.get(API_COMUNI)
          .then(response => {
            if(response.data){
              const out = response.data.map(i => {
                return {
                  value: i.id,
                  label: i.nome
                }
              });
              return out;
            }
            return [];
          })
          .catch(err => throwError(err));
    }
    /* async loadComuni(){
      return axios.get('/comuni.json')
          .then(response => {
            return response.data || [];
          })
          .catch(err => throwError(err));
    } */

    async sendQuoteRequest(data) {
      return await axios.post(API_FORM_SEND, data )
        .then(res => {
          if(!res.data) return false;
          return res.data;
        })
        .catch(err => err)
    }

    async addOptional (data) {
      return axios.post(API_ENDPOINT_V2 + 'addEquipments/',data)
          .then(response => {
              return response;
          })
          .catch(err => throwError(err));
    }

    async removeOptional (data) {
      return axios.post(API_ENDPOINT_V2 + 'removeEquipments/',data)
          .then(response => {
              return response;
          })
          .catch(err => throwError(err));
    }

    async getOffers() {
      const cached = await localStorage.getItem('alloffers');
      if(cached) return new Promise(r => r(JSON.parse(lz.decompressFromUTF16(cached))));
      
      return axios.get(API_ENDPOINT + 'alloffers')
      .then(response => {
        localStorage.setItem('alloffers',lz.compressToUTF16(JSON.stringify(response.data)))
        return response.data || [];
      }).catch(err => {console.log(err);return []});
    }

    prepareDatabase(data) {
        const database = data.map(item => {
          const { 
              submodelId,
              submodelCommercialName,
              submodelUrlCode,submodelCommercialUrlCode,
              icons,images,
              minPrice,maxPrice,
              makeName, makeUrlCode, makeLogo,
              bodyType,bodyTypeUrlCode,doors,
            } = item.model;
          return {
            model: { 
              submodelId,
              submodelCommercialName,
              submodelUrlCode,submodelCommercialUrlCode,
              icons,images,
              minPrice,maxPrice,
              makeName, makeUrlCode, makeLogo,
              bodyType,bodyTypeUrlCode,doors,
            },  
            summary: item.summary
          };
        })
        //window.localStorage.setItem('urcarDbModels', JSON.stringify(database));
        return database;
      }
}

export default Api;