import React, {Component} from 'react';
import Page, {Grid} from '../../Components/Page';
import BodyTypeSelector from '../../Components/Selectors/BodyTypeSelector';
import './Starters.css';
import SidebarStart from '../../Components/Sidebars/SidebarStart';
import { bodyTypes,cardata} from '../../assets/data'
import {connect} from 'react-redux';
import { withRouter,Redirect } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {   
    console.log("BodyType",state, ownProps);
    return {
        bodyType: state.cars.bodyType
    };
}

class BodyType extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedBodyTypes: [],
            goto: null
        }

        this.getSelected = this.getSelected.bind(this);
        this.goToSelected = this.goToSelected.bind(this);
    }
    
    getCount() {
        let counter  = 0;
        for(const i in bodyTypes){
            if(this.state.selectedBodyTypes.includes(bodyTypes[i].bodyTypeUrlCode)) {
                const val = parseInt(cardata.bodyCount[bodyTypes[i].bodyTypeUrlCode]);
                counter += val;
            }
        }
        return counter;
    }

    getSelected(it) {
        console.log(it.target.id);

        this.setState({goto: '/modelli/*' + it.target.id})
    }

    goToSelected(val) {
        console.log('selected', this.state.selectedBodyTypes);
        this.setState({goto: '/modelli'})
    }
    
    render() {
        if(this.state.goto) return <Redirect to={this.state.goto} />;

        const buttonLabel = this.state.selectedBodyTypes.length > 0 ? 'Mostra ' + this.getCount() + ' risultati' : null;
        return (
            <Page title="Scegli la marca">
                <Grid>
                    <div className="Entry-page-left" style={{background:'#F7F7F7',padding:'40px'}}>
                        <SidebarStart 
                            from="bodyType" 
                            selectedBodyTypes={this.state.selectedBodyTypes} 
                            buttonLabel={buttonLabel}
                            click={this.goToSelected} />
                    </div>
                    <div className="Entry-page-right">
                        <BodyTypeSelector click={this.getSelected} filter={[]}  />
                    </div>
                </Grid>
            </Page>
        )
    }
}



export default connect(mapStateToProps)(withRouter(BodyType));