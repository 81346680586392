import React from 'react';

const Engine = (props) => {
    
    return (
        <section className="engine">
            <div className="DetailsEngine-content">
                <ul>
                    <li>
                        <p className="DetailsSpecListItem">Cilindrata</p> 
                        <p className="DetailsSpecListItemData">
                        <span>{props.item.engine.cylinders} </span>cilindri / <span >{props.item.engine.cm3}</span> cm3</p>
                    </li>
                        <li>
                        <p className="DetailsSpecListItem">Potenza</p> 
                        <p className="DetailsSpecListItemData">
                            <span>{props.item.engine.HP} </span>CV / <span>{props.item.engine.kw}</span> kw</p> 
                    </li>
                    <li>
                    <p className="DetailsSpecListItem">Velocità massima</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.engine.maxSpeed}</span> km/h</p> 
                    </li>
                    <li>
                        <p className="DetailsSpecListItem">Coppia massima</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.engine.maxPower}</span> N/m a <span>{props.item.engine.HP}</span> giri/min </p> 
                    </li>
                    <li>
                        <p className="DetailsSpecListItem">Accelerazione</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.engine.acceleration}</span>s (0-100km/h)</p> 
                    </li>
                    <li>
                        <p className="DetailsSpecListItem">Alimentazione</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.calculatedFuelType}</span></p>
                    </li>
                    <li>
                        <p className="DetailsSpecListItem">Cambio</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.gearboxType}</span> / <span>{props.item.transmissionNumGears}</span> marce</p>
                    </li>
                </ul>
            </div>
            
        </section>
    )
}

export default Engine;