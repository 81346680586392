export const validateQuote = (confirms, formData) => {
    const { Noleggio, /* Acquisto */ } = formData.quotes;
    if (!Noleggio.sel_durata /* && !Acquisto.sel_durata */) return { ok: false, err: 'no-offers' };
    const { tipo, nome, cognome, email, tel, comune, cache } = formData;
    //console.log(formData)
    
    if (!tipo) {
        return { ok: false, err: 'no-type'};
    }

    if (parseInt(tipo) === 1) {
        if (!formData.cf || !formData.cf.match(/^[a-zA-Z0-9]{16}$/)) {
            return { ok: false, err: 'no-valid', field: 'codice fiscale' };
        }
    } else {
        if (!formData.inizio_attivita) {
            return { ok: false, err: 'no-required', field: 'inizio attività' };
        }
        if (!formData.p_iva || !formData.p_iva.match(/^[a-zA-Z0-9]{10,20}$/)) {
            return { ok: false, err: 'no-valid', field: 'partita iva' };
        }

        if (!formData.ragione_sociale || nome.ragione_sociale < 2) {
            return { ok: false, err: 'no-required', field: 'ragione sociale' };
        }
    }

    if (nome && nome.length > 2) {
        if (!nome.match(/^[a-zA-Z]+$/)) {
            return { ok: false, err: 'no-required', field: 'nome' };
        }
    } else {
        return { ok: false, err: 'no-required', field: 'nome' };
    }
    if (cognome && cognome.length > 2) {
        if (!cognome.match(/^[a-zA-Z]+$/)) {
            return { ok: false, err: 'no-required', field: 'cognome' };
        }
    } else {
        return { ok: false, err: 'no-required', field: 'cognome' };
    }
    
    if (email) {
        const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;
        if(!pattern.test(email)){
            return { ok: false, err: 'no-valid', field: 'email' };
        }
    } else {
        return { ok: false, err: 'no-required', field: 'email' };
    }
    
    if (tel) {
        if (!tel.match(/^[+]?[0-9.]{5,15}$/)) {
            return { ok: false, err: 'no-valid', field: 'telefono' };
        }
    } else {
        return { ok: false, err: 'no-required', field: 'telefono' };
    }
    //const optionalsString = formData.optionals && formData.optionals.length? ' | Optionals: ' + formData.optionals.map(i => i.description).join(',') : '';
    const suggestedString = formData.suggested && formData.suggested.length? formData.suggested.join(',') : '';
    const {vehicle} = formData;
    console.log("Form",vehicle)
    let objSend = {
        confirm: confirms.confirm ? 'confirm' : '',
        confirm_b: confirms.confirm_b ? 'confirm_b':'',
        confirm_c: confirms.confirm_c ? 'confirm_c':'',
        confirm_d: confirms.confirm_d ? 'confirm_d':'',
        opvid: formData.opvid || '',
        tipo: formData.tipo,
        //tipo 1
        cf: parseInt(formData.tipo)===1 ? formData.cf.toUpperCase() : null,
        //tipo non 1
        p_iva: parseInt(formData.tipo)!==1 ? formData.p_iva.toUpperCase() : null,
        ragione_sociale: parseInt(formData.tipo)===1 ? formData.ragione_sociale : null,
        inizio_attivita: parseInt(formData.tipo)===1 ? formData.inizio_attivita : null,
        nome: nome,
        cognome: cognome,
        email: email,
        tel: tel,
        comune: comune,
        mesi: Noleggio.sel_durata || '',
        canone: Noleggio.costo_iniziale || '',
        km: Noleggio.sel_km_annuali || '',
        from_config: 1,
        //interessato: (formData.selectedOffers || []).join(",") + optionalsString + suggestedString,
        //nuovo_canone: Acquisto.costo_iniziale || '',
        //nuovo_mesi: Acquisto.sel_durata || '',
        vehicleType: vehicle.vehicleType,
        vehicleUrl: `${vehicle.makeUrlCode}/${vehicle.submodelCommercialUrlCode}/${vehicle.versionUrlCode}${vehicle.optionCode}`,
        makeUrlCode: formData.makeUrlCode,
        modelUrlCode: formData.submodelCommercialUrlCode,
        versionUrlCode: vehicle.versionUrlCode,
        cache,
        auto: `${vehicle.makeName} ${vehicle.submodelCommercialName} ${vehicle.versionName}`,
        img_file: vehicle.icons.icon800,
        offerta: 'generic',
        offers: suggestedString
    }
    return {ok: true, object: objSend};
}

export const sendQuoteForm = (obj) => {
    let formData = new FormData();
    for (let named in obj) {
        if(obj[named]) formData.append(named, obj[named]);
      }
    
    return formData;
}