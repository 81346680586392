import React from 'react';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import { withRouter,Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import store from '../../store/store';
import actions from '../../store/actions';

import Page from '../../Components/Page';
import Grid from '../../Components/Page/Grid';
import CarModelWidget from '../../Components/CarModel/CarModelWidget';
import DetailsHeadbar from '../../Components/DetailsHeadbar';
import {Dimensions, VersionFilter} from '../../Components/Details';
import Versions from '../../Components/Details/Versions';
import VersionDetails from '../../Components/Details/VersionDetails';
import { Button } from '../../Components/FormItems';
import AnimatedIcon from '../../Components/AnimatedIcon/AnimatedIcon';

import Api from '../../Modules/Api';
import { ParseEquipments } from '../../Modules/Equipments';

import 'react-toastify/dist/ReactToastify.css';
import './ModelDetails.css';

/*import toMoney from '../../Modules/Utils';*/
import ModalRequest from '../../Components/ModalRequest';
import { validateQuote, sendQuoteForm } from '../../Modules/Form';
import ModalRequestThankyou from '../../Components/ModalRequest/ModalRequestThankyou';
import ModalOptionalsAlert from '../../Components/Details/ModalOptionalsAlert';
import config from '../../Appconfig';
import toMoney from '../../Modules/Utils';
import Cookies from 'js-cookie';
  
Modal.setAppElement('#root');

const mapStateToProps = (state, ownProps) => {
    /* console.log("statemap",state, ownProps);
    console.log('off',state.offers.data); */
    return {
        selectedModel: state.model.selectedModel,
        selectedVersion: state.model.selectedVersion,
        optionals: state.model.optionals,
        color: state.model.color,
        database: state.cars.database,
        offers: state.offers.data,
    };
}

const modalStyles = {
    content : {
      top                   : '30%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -30%)',
      paddingBottom         : 40,
      paddingLeft           : 40,
      paddingRight          : 40,
      paddingTop            : 20,
    }
};

const modalRequestStyles = {
    content : {
      zIndex                : 9999,
      top                   : '10%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-20%',
      transform             : 'translate(-50%, -5%)',
      paddingBottom         : 40,
      paddingLeft           : 40,
      paddingRight          : 40,
      paddingTop            : 40,
      width                 : '80%',
      height                : '80%',
      maxWidth              : 920,
      
    }
};

class ModelDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: props.model || null,
            versions: [],
            loading: false,
            icon: null,
            editEquipments: false,
            colors: {interior:[],external:[]},
            selectedColor: null,
            filters: {
                gearboxType:[],
                fuelType: [],
                traction: [],
                engine: [],
                trim: [],
                version: [],
            },
            filtersDefault: {
                gearboxType:[],
                fuelType: [],
                traction: [],
                engine: [],
                trim: [],
                version: [],
            },
            modalRequest: false,
            modalOptionals: false,
            modalData: {
                title: 'Modal',
                itemlist: [],
                action: null,
            },
            errorModal: false,
            errorModalData: {},
            scrollTop: 0,
            formData: {
                suggested: [],
                tipo: null,
                ragione_sociale: '',
                p_iva: '',
                cf: '',
                tel: '',
                comune: '',
                nome: '',
                cognome: '',
                email: '',
                inizio_attivita: '',
                confirm: 'confirm',

                selectedOffers: ['Noleggio'],
                quotes: {
                    Noleggio: {costo_iniziale: 1500, sel_km_annuali: 12000, sel_durata: 36},
                    Acquisto: {}
                }
            },
            confirms: {
                all: false,
                confirm: false,
                confirm_b: false,
                confirm_c: false,
                confirm_d: false,
            },
            optionalFilter: false,
            optionalHash: '',
            similarOffers: 0,
            similarMakeOffers: 0,
            equipments: {},
            comuni: [],
        }
        this.api = new Api();

        this.click = this.click.bind(this);
        this.change = this.change.bind(this);
        this.selectVersion = this.selectVersion.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.acceptAllOptionalChange = this.acceptAllOptionalChange.bind(this);
        this.acceptSingleOptionalChange = this.acceptSingleOptionalChange.bind(this);
        this.selectColor = this.selectColor.bind(this);
        this.deselectVersion = this.deselectVersion.bind(this);
        this.configure = this.configure.bind(this);
        this.openRequest = this.openRequest.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.formChange = this.formChange.bind(this);
        this.updateSub = this.updateSub.bind(this);
        this.updateChecks = this.updateChecks.bind(this);
        this.closeError = this.closeError.bind(this);
        this.suggestedUpdate = this.suggestedUpdate.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.removeAllOptionals = this.removeAllOptionals.bind(this);
    }

    getMetaDesc = async () => {
        if(this.props.selectedModel && this.props.selectedModel.model) {
            const { model } = this.props.selectedModel;
            if(model.versions) {
                model.versions = model.versions.map(q => {
                    q.price = q.priceMsrpPlusDelivery;
                    return q;
                })
            }
            if(this.props.selectedVersion) {
                const equipments = this.state.equipments;
                const ver = {...this.props.selectedVersion, equipments};
                const equips = [];
                for(const i in equipments){
                    for(const z in equipments[i]){
                        if( equipments[i][z].equipment && equipments[i][z].equipment.type==='STANDARD'){
                            equips.push(equipments[i][z].equipment.name)
                        }
                    }
                }
                return model.makeName + ' ' + model.submodelCommercialName + ' ' + ver.versionName + ', '
                    + model.bodyType  + ', '
                    + 'cambio ' + ver.gearboxType 
                        + (ver.transmissionNumGears? ' a ' + ver.transmissionNumGears + ' marce' : '') + ', '
                    + 'alimentazione ' + ver.calculatedFuelType + ', '
                    + 'trazione ' + ver.traction  + ', '
                    + toMoney(ver.price) + ' prezzo di listino. Equipaggiamenti di serie: '
                    + equips.join(', ') + '. '
                    + 'Scopri come ottenerla scontata con Urcar!'
            } else {
                return model.makeName + ' ' + model.submodelCommercialName + ', '
                    + toMoney(model.minPrice) + ' prezzo di listino. '
                    + 'Scopri come ottenerla scontata con Urcar!'
            }
        }
        return null;
    }

    async componentDidMount(){
        window.scrollTo(0,0);
        console.log("COOKIES",Cookies.get("opvid"));
        const {make,model,version} = this.props.match.params;
        console.log(this.props.match.params, this.props.model, this.props.version)
        this.setState({loading: true});
        if(!this.props.offers || this.props.offers.length === 0){
            console.log("loading offers")
            await this.api.getOffers().then(r => {
                store.dispatch({type: actions.OFFERS_COMPLETED, payload: r})
            })
        } else {
            console.log("offers already loaded")
        }
        
        const resp = await this.api.loadComuni();
        this.setState(old => {
            old.comuni = resp;
            return old;
        });
        
        console.log('ModelDetails componentDidMount',make,model,version)
        await this.api.get('auto/' + make + '/' + model)
            .then(async response => {
                if(!response || !response.data) {
                    window.location.href = '/modelli';
                    return;
                }
                const filters = this.prepareFilters(response.data.model.versions);
                const orderedV = response.data.model.versions.sort((a,b) => {
                    if(a.versionName === b.versionName) return 0;
                    return a.versionName.localeCompare(b.versionName)
                })
                //console.log('filters',filters)
                this.setState({ 
                    model: response.data.model, 
                    versions: orderedV, 
                    loading: false, 
                    icon: response.data.model.icons[0].icon800,
                    filters: filters,
                    filtersDefault: JSON.parse(JSON.stringify(filters)),
                });
                
                store.dispatch({
                    type: actions.VERSIONS_COMPLETED,
                    payload: response.data
                });

                if(version) {
                    const v = response.data.model.versions.find(i => i.versionUrlCode===version);
                    console.log("checking version",v)
                    if(v){
                        const equipments = await this.api.get('equip/' + v.versionId)
                        .then(response => {
                            return response.data;
                        });
                        //console.log('didMount Selected version', v,equipments)
                        store.dispatch({
                            type: actions.VERSION_SELECTED,
                            payload: {...v,equipments: equipments}
                        });
                        this.setState({
                            editEquipments: true,
                            loading: false, 
                            equipments
                        });

                        window.scrollTo(0,60)
                        
                    }
                }
            }).catch(err => console.error(err));
        setTimeout(() => {

            this.filterOffers(this.props.offers)
        }, 150);
        
    }

    
    updateSub(varname, value) {
        this.setState(old => {
            old.formData[varname] = value;
            return old;
        })
    }

    suggestedUpdate(value) {
        console.log('suggestedUpdate',value)
        if(this.state.formData.suggested.includes(value)){
            this.setState(old => {
                old.formData.suggested.splice(old.formData.suggested.indexOf(value),1);
                return old;
            })
        } else {
            this.updateSub('suggested',this.state.formData.suggested.concat([value]))
        }
        
    }

    filterOffers(offers) {
        if(!this.state.model) return;
        let foundOffers = 0;
        let foundMakeOffers = 0;
        if(!offers) offers = [];
        if(!Array.isArray(offers)) offers = Object.values(offers);
        (offers).forEach(i => {
            if(this.state.model.makeUrlCode === i.makeUrlCode){
                foundMakeOffers++;
                if(this.state.model.modelUrlCode === i.modelUrlCode){
                    foundOffers++;
                } else { 
                    const modelname = this.state.model.makeName.toUpperCase() + ' ' + this.state.model.modelName.toUpperCase();
                    if(i.nome.match(new RegExp(modelname, 'ig'))) {
                        foundOffers++;
                    }
                }
            }
        })
        this.setState({similarOffers: foundOffers, similarMakeOffers: foundMakeOffers})
    }

    click(e) {
        try{
            const json = JSON.parse(e.target.attributes['data-obj'].value);
            console.log('ModelDetails click',this.props.optionals, this.props.selectedVersion, json);
            if(!this.props.optionals.some(i => i.idEquipment===json.idEquipment)){
                const data = {
                    versionId: this.props.selectedVersion.versionId,
                    currentConfig: this.props.optionals.map(i => i.idEquipment).join(','),
                    addConfig: json.idEquipment,
                }
                this.addOptionals(data,json);
            } else {
                const data = {
                    versionId: this.props.selectedVersion.versionId,
                    currentConfig: this.props.optionals.map(i => i.idEquipment).join(','),
                    removeConfig: json.idEquipment,
                }
                this.removeOptionals(data,json);
            }
        } catch(err) {
            console.log(err)
        }
    }

    createOptionalsHash = (equips, raw) => {
        const code = equips.map(i => i.manufacturerCode || i.manufactorCode)
            .sort((i,j) => i>j ? 1 : i===j? 0 : -1);
        if(raw === true) return code.join("|");
        return btoa(code.join("|"));
    }

    updateHash(newOptionals) {
        if(!newOptionals) return;
        if(newOptionals.length===0){
            //window.location.href = window.location.href.substr(0,window.location.href.indexOf('#')-1)
            window.history.replaceState(null, null, ' ');
            return;
        }
        let optstring = '';
        try{
            console.log('code',newOptionals)
            const code = newOptionals.map(i => new Buffer(i.manufacturerCode || i.manufactorCode))
            .sort((i,j) => i>j ? 1 : i===j? 0 : -1);
            console.log('code',code)
            optstring = new Buffer(code.join('|')).toString('base64');

        } catch(err) {
            console.error(err)
            optstring = '';
        }
        window.location.hash = optstring;
    }

    initOptionals = (newOptionals) => {
        store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: newOptionals});
        this.updateHash(newOptionals);
    }

    addOptionals(data,json) {
        console.log('addOptional',data,json)
        this.api.addOptional(data).then( result => {
            if(result.data && result.data.config){
                const conf = result.data.config;
                if(conf.status === "OK"){
                    const newOptionals = this.props.optionals.concat([json]);
                    toast.success(`Aggiunto: ${json.description}`, {bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER})
                    store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: newOptionals});
                    this.updateHash(newOptionals);
                } else {
                    let toOpen = false;
                    if(conf.alternatives && conf.alternatives.decision){
                        const modalData = ParseEquipments(conf,json);
                        if(modalData) {
                            toOpen = true;    
                            this.setState({ modalData })
                        }
                    }
                    if(toOpen) this.openModal();
                }
            } else {
                toast.error(`Errore di caricamento`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER})
            }
        }).catch( error => console.log(error))
    }

    removeAllOptionals() {
        const newOptionals = [];
        store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: newOptionals});
        this.updateHash(newOptionals);
        toast.warn(`Optional rimossi`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER})
    }

    removeOptionals(data,json) {
        this.api.removeOptional(data).then( result => {
            if(result.data && result.data.config){
                const conf = result.data.config;
                if(conf.status === "OK"){
                    const newOptionals = this.props.optionals.filter(i => i.idEquipment !== json.idEquipment);
                    toast.warn(`Rimosso: ${json.description}`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER})
                    store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: newOptionals});
                    this.updateHash(newOptionals);
                } else {
                    let toOpen = false;
                    if(conf.alternatives && conf.alternatives.decision){
                        const modalData = ParseEquipments(conf,json);
                        if(modalData) {
                            toOpen = true;    
                            this.setState({ modalData })
                        }
                    }
                    if(toOpen) this.openModal();
                }
            } else {
                toast.error(`Errore di caricamento`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER})
            }
        }).catch( error => console.log(error))
    }

    selectColor(id,type) {
        console.log('selectColor',id,type)
        let color = this.state.colors[type].filter(i => i.equipment.colours.some(j => j.id === id))
        if(color.length === 0) return false;
        if(color.length > 1) return false;
        [color] = color[0].equipment.colours.filter(i => i.id===id);
        toast.success(`Colore selezionato: ${color.description}`)
        store.dispatch({ type: actions.COLOR_CHANGED,  payload: color }, {bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER});
        
    }

    async selectVersion(e) {
        if(!e) {
            store.dispatch({
                type: actions.OPTIONALS_CHANGED,
                payload: []
            });
            store.dispatch({
                type: actions.VERSION_SELECTED,
                payload: null
            });
            return
        }
        const {target} = e;
        const v = this.state.versions.find(i => i.versionUrlCode === target.id);
        console.log("SELECTED ", target.id)
        if(v){
            const equipments = await this.api.get('equip/' + v.versionId)
            .then(response => {
                return response.data;
            });
            store.dispatch({
                type: actions.VERSION_SELECTED,
                payload: {...v,equipments}
            });
            this.setState({
                editEquipments: true,
                loading: false, 
                equipments
            })
            console.log("version equips",equipments)
        }
    }

    configure(e) {
        this.setState({
            editEquipments: true
        })
        this.selectVersion(e);
    }

    change(e) {
        console.log('ModelDetails change',e.target.className, e.target.name);
        const { className, name } = e.target;
        switch(className){
            case 'traction':
            case 'gearboxType':
            case 'fuelType':
            case 'engine':
            case 'trim':
            case 'version':
                const newFilters = {...this.state.filters};
                if(newFilters[className].includes(name)){
                    newFilters[className].splice(newFilters[className].indexOf(name),1);
                } else {
                    newFilters[className].push(name);
                }
                this.setState({filters: newFilters});
                break;
            default: break;
        }
        
    }

    prepareFilters(versions) {
        const propsToWatch = {
            fuelType: this.state.filters.fuelType.concat([])|| [],
            gearboxType: this.state.filters.gearboxType.concat([]) || [],
            traction: this.state.filters.traction.concat([]) || [],
            engine: this.state.filters.engine.concat([]) || [],
            trim: this.state.filters.trim.concat([]) || [],
            version: this.state.filters.version.concat([]) || [],
        }
        for (const x in versions) {
            if (!propsToWatch.fuelType.includes(versions[x].calculatedFuelType) && versions[x].calculatedFuelType) {
                propsToWatch.fuelType.push(versions[x].calculatedFuelType);
            }
            if (!propsToWatch.gearboxType.includes(versions[x].gearboxType) && versions[x].gearboxType) {
                propsToWatch.gearboxType.push(versions[x].gearboxType);
            }
            if (!propsToWatch.traction.includes(versions[x].traction) && versions[x].traction) {
                propsToWatch.traction.push(versions[x].traction);
            }
            if (!propsToWatch.engine.includes(versions[x].engine.liters) && versions[x].engine.liters) {
                propsToWatch.engine.push(versions[x].engine.liters);
            }
            if (!propsToWatch.trim.includes(versions[x].trimName) && versions[x].trimName) {
                propsToWatch.trim.push(versions[x].trimName);
            }
            if(versions[x].trimName !== '-'){
                const vname = versions[x].versionName.slice(0, versions[x].versionName.toUpperCase().indexOf(versions[x].trimName.toUpperCase())).toUpperCase().trim()
                if (!propsToWatch.version.includes(vname)) {
                    propsToWatch.version.push(vname);
                }
            }
          }
          return propsToWatch;
    }

    closeModal() {
        this.setState({modalOptionals: false, modalRequest: false, sentSuccess: false});
    }
    openModal() {
        this.setState({modalOptionals: true, modalRequest: false});
    }
    openRequest(e) {
        this.selectVersion(e);
        if(config.appTarget === 'admin'){
            this.setState({modalOptionals: false, modalRequest: false});
            const callUrl = window.location.href.replace(/(\/)?#/,'/').replace('versioni','singleCar').replace(window.location.hostname,'api.urcar.it/vx');
            if(window.parent) window.parent.postMessage(callUrl, "*");
        } else {
            this.setState({modalOptionals: false, modalRequest: true});
        }
    }
    afterOpenModal() {
        //console.log('afterOpenModal')
    }
    acceptAllOptionalChange(e) {
        const { dec, itemlist, request } = this.state.modalData;
        switch(dec) {
            case "ADD_ALL":
                const newOptionals = this.props.optionals.concat(itemlist,[request]);
                store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: newOptionals});
                this.updateHash(newOptionals)
                toast.success(`Aggiunti: ${itemlist.map(i => i.description).join(', ')}, ${request.description}`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER});
                break;
            case "REMOVE_ALL":
                const updatedOptionals = this.props.optionals.filter(i => {
                    if(itemlist.some(h => h.idEquipment === i.idEquipment)) return false; 
                    if(i.idEquipment === request.idEquipment) return false;
                    return true;
                });
                store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: updatedOptionals.concat([request])});
                this.updateHash(updatedOptionals.concat([request]))
                toast.warn(`Rimossi: ${itemlist.map(i => i.description).join(', ')}`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER});
                toast.success(`Aggiunto: ${request.description}`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER});
                break;
            default: break;
        }
        this.setState({modalOptionals: false});
    }

    acceptSingleOptionalChange(e) {
        switch(this.state.modalData.dec) {
            case "ADD_ONE_OF":
                const itemToAdd = this.state.modalData.itemlist.find(i => i.idEquipment === parseInt(e.target.id));
                if(!itemToAdd) break;
                const newOptionals = this.props.optionals.concat([itemToAdd,this.state.modalData.request]);
                store.dispatch({ type: actions.OPTIONALS_CHANGED,  payload: newOptionals});
                this.updateHash(newOptionals);
                toast.success(`Aggiunti: ${itemToAdd.description}, ${this.state.modalData.request.description}`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER});
                break;
            case "REMOVE_ONE_OF":
                
                break;
            default: break;
        }
        this.setState({modalOptionals: false});
    }

    closeError() {
        this.setState({errorModal: false})
    }

    updateChecks(e){
        const { name, checked } = e.target;
        if(name==='all') {
            this.setState(old => {
                old.confirms = {
                    all: checked,
                    confirm: checked,
                    confirm_b: checked,
                    confirm_c: checked,
                    confirm_d: checked,
                };
                return old;
            })
        } else {
            this.setState(old => {
                const { confirm, confirm_b, confirm_c, confirm_d } = old;
                old.confirms.all = confirm && confirm_b && confirm_c && confirm_d
                old.confirms[name] = checked;
                return old;
            })
        }
        
    }

    deselectVersion() {
        this.selectVersion(null)
        this.setState({
            editEquipments: false
        })
    }

    sendForm(e) {
        if(!this.props.selectedVersion) return;
        console.log('SENDFORM');
        const { formData, confirms, model } = this.state;
        
        const { makeUrlCode, submodelCommercialUrlCode, makeName, submodelCommercialName, bodyType, modelYear, trimManufacturerCode } = this.state.model;

        const { versionName, versionId, gearboxType, calculatedFuelType, engine,traction, seats } = this.props.selectedVersion;

        const cache = {
            makeName, submodelCommercialName, versionName, bodyType, modelYear, seats,
            traction, calculatedFuelType, gearboxType, trimManufacturerCode, versionId,
            engine: engine.cm3, vehicleType: 'car'
        }
        
        const vehicle = {...this.props.selectedVersion, makeUrlCode, submodelCommercialUrlCode, ...cache, icons: model.icons};
        const optionCode = this.props.optionals.length>0? '/' + this.createOptionalsHash(this.props.optionals) : '';
        console.log("optionals", this.props.optionals)
        vehicle.optionCode = optionCode;
        formData.optionals = this.props.optionals;
        formData.cache = JSON.stringify(cache);
        formData.vehicle = vehicle;
        formData.opvid = Cookies.get('opvid');

        if(!confirms.confirm) {
            this.setState({errorModalData: {msg: "Devi accettare almeno i termini per le finalità commerciali"}, errorModal: true});
            return;
        }
        console.log("DATA FORM",formData)
        
        const outform = validateQuote(confirms, formData)
        if(!outform) return;
        let reason;
        if(!outform.ok){
            switch(outform.err){
                default: 
                    reason = 'Errore generico';
                    break;
                case "no-valid":
                    reason = 'Campo non valido: ' + outform.field;
                    break;
                case "no-required":
                    reason = 'Campo richiesto mancante: ' + outform.field;
                    break;
                case "no-type":
                    reason = 'Selezionare una tipologia utente';
                    break;
                case "no-offers":
                    reason = 'Selezionare un noleggio o un acquisto';
                    break;
            }
            this.setState({errorModalData: {msg: reason}, errorModal: true});
            return;
        }

        const data2send = sendQuoteForm(outform.object);

        console.log("DATA FORM OBJ",outform)
        for(var pair of data2send.entries()) {
            console.log(pair[0]+ ', '+ pair[1]); 
         }
        
        this.api.sendQuoteRequest(data2send).then(result => {
            if(result.msg === "Grazie per averci contattato! La tua richiesta sarà presto presa in carico, intanto controlla la tua casella di posta elettronica.<br>A presto.") {
                this.closeModal();
                setTimeout(() => this.setState({sentSuccess: true, sentMessage: result.msg}), 200);
            }
        }).catch(err => {
            this.setState({errorModalData: {msg: err.message}, errorModal: true});
        })
    }

    formChange(e) {
        const {name,value} = e.target;
        console.log('formchange',name,value)
        this.setState(old => {
            old.formData[name] = value;
            return old;
        })
    }

    checkAll(e) {
        if(!this.state.filters) return false;
        let result = true;
        for(const i in this.state.filtersDefault){
            if(this.state.filtersDefault[i].length !== this.state.filters[i].length) result = false;
        }
        if(!result) {
            this.setState({
                filters: JSON.parse(JSON.stringify(this.state.filtersDefault))
            })
        } else {
            const keys = Object.keys(this.state.filtersDefault);
            const newfilt = {};
            for(const x of keys) newfilt[x] = []
            this.setState({filters: newfilt});
        }
    }

    getVersionLinkUrl() {
        if(!this.state.model) return '';
        return this.state.model.makeUrlCode+'/'+this.state.model.submodelCommercialUrlCode
    }

    render() {
        return (
            <Page title="Dettagli modello"
                selectedVersion={this.props.selectedVersion} 
                model={this.props.selectedModel? this.props.selectedModel.model : null}
                optionals={this.props.optionals}
                openRequest={this.openRequest}
                metaTitle={
                    this.props.selectedModel? 
                        'Urcar! ' + this.props.selectedModel.model.makeName + ' ' 
                            + this.props.selectedModel.model.submodelCommercialName 
                            + (this.props.selectedVersion? this.props.selectedVersion.versionName : '')
                        : 'Urcar! Configura la tua vettura'
                }
                metaUrl={
                    this.props.selectedModel? 
                        'https://configura.urcar.it/versioni/' 
                            + this.props.selectedModel.model.makeUrlCode + '/'
                            + this.props.selectedModel.model.submodelCommercialUrlCode 
                            + (this.props.selectedVersion? '/' + this.props.selectedVersion.versionUrlCode : '')
                    : null
                }
                metaImage={
                    this.props.selectedModel? 
                        this.props.selectedModel.model.images[0].image800
                        : null
                }
                metaDescription={this.getMetaDesc()}
                opvid={Cookies.get("opvid") || null}
            >
                
                <ToastContainer />
                
                <ModalOptionalsAlert 
                    isOpen={this.state.modalOptionals}
                    onAfterOpen={this.afterOpenModal}
                    closeModal={this.closeModal}
                    style={modalStyles}
                    contentLabel="Configurazione Accessori"
                    title={this.state.modalData.title}
                    itemlist={this.state.modalData.itemlist}
                    label={this.state.modalData.action}
                    click={this.acceptSingleOptionalChange}
                    acceptAllOptionalChange={this.acceptAllOptionalChange}
                />
                
                <ModalRequestThankyou
                    isOpen={this.state.sentSuccess}
                    closeModal={this.closeModal}
                    style={modalRequestStyles}
                    contentLabel="Richiesta inviata" 
                />
                
                <Modal
                    isOpen={this.state.modalRequest}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={modalRequestStyles}
                    contentLabel="Sfida il PCS!"
                >
                    <ModalRequest
                        model={this.state.model}
                        offers={this.props.offers}
                        optionals={this.props.optionals}
                        selectedVersion={this.props.selectedVersion}
                        versionsLength={this.state.versions.length || null}
                        formData={this.state.formData}
                        formChange={this.formChange}
                        updateSub={this.updateSub}
                        updateChecks={this.updateChecks}
                        formClick={this.sendForm}
                        confirms={this.state.confirms}
                        error={this.state.errorModal ? this.state.errorModalData : null }
                        closeError={this.closeError}
                        suggestedUpdate={this.suggestedUpdate}
                        comuni={this.state.comuni}
                    />
                </Modal>
                
                <Grid className="modelDetailsGrid">
                    <div className="ModelDetails-page-left" style={{background:'#F7F7F7',padding:40}}>
                        <CarModelWidget 
                            marginTop={this.state.scrollTop || 0}
                            selectedVersion={this.props.selectedVersion || null} 
                            selectedModel={this.props.selectedModel || {model:null, summary: null}}
                            openRequest={this.openRequest} 
                            optionals={this.props.optionals}
                        />
                    </div>
                    <div className="ModelDetails-page-right">
                        <DetailsHeadbar 
                            similarOffers={this.state.similarOffers} 
                            similarMakeOffers={this.state.similarMakeOffers}
                            change={this.orderSelect} 
                            selected={this.state.orderby}
                            model={this.state.model}
                        />
                        <div className="ModelDetails">
                            {
                                !this.props.selectedVersion && config.appTarget === 'admin' ?
                                    null : 
                                    <Dimensions  
                                        icon={this.state.icon} 
                                        item={this.props.selectedVersion || null} 
                                    />
                            }
                            {
                                this.state.loading ? 
                                    <div>
                                        <AnimatedIcon />
                                    </div>
                                :
                                (this.props.selectedVersion && this.state.editEquipments) ?
                                    <div>
                                        <div style={{display: 'grid', gridTemplateColumns: '1fr 200px', justifyContent: 'space-between'}}>
                                            <Link to={"/versioni/" + this.state.model.makeUrlCode + "/" + this.state.model.submodelCommercialUrlCode}>
                                                <Button className="fucsia" 
                                                    style={{width: 'auto', marginBottom: 30}} 
                                                    label={"Torna a tutte le versioni della " + ((this.state && this.state.model && this.state.model.submodelCommercialName) || '')}
                                                    name="backToVersions" 
                                                    click={this.deselectVersion} 
                                                />
                                            </Link>
                                            <Button className="fucsia" 
                                                style={{width: 200, marginBottom: 30, fontWeight: 700 }} 
                                                label={config.appTarget === 'admin' ? "Seleziona auto" : "Richiedila ora!"}
                                                name="orderNow" 
                                                id={this.props.selectedVersion.versionUrlCode}
                                                click={this.openRequest}
                                            />
                                        </div>
                                        <h1 id="VersionTitle" style={{fontWeight: 600}}>
                                            {this.state.model.makeName}  {this.state.model.submodelCommercialName}  <span style={{fontWeight: 900}}>{this.props.selectedVersion.versionName}</span>
                                        </h1>
                                        <VersionDetails 
                                            colors={this.state.colors} 
                                            selected={this.state.selectedColor} 
                                            click={this.click} 
                                            version={this.props.selectedVersion || {}} 
                                            optionals={this.props.optionals} 
                                            selectColor={this.selectColor}
                                            showOptionals={this.state.optionalFilter}
                                            removeAllOptionals={this.removeAllOptionals}
                                            change={e => this.setState({optionalFilter: !this.state.optionalFilter})}
                                            initOptionals={this.initOptionals}
                                            equipments={this.state.equipments}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <div style={{display: 'grid', gridTemplateColumns: '1fr 200px', justifyContent: 'space-between'}}>
                                            <Link to={"/modelli/" + ((this.state.model && this.state.model.makeUrlCode) || '')}>
                                                <Button className="fucsia" 
                                                    style={{width: 'auto', marginBottom: 30}} 
                                                    label={"Torna a tutti i modelli " + ((this.state.model && this.state.model.makeName) || '')}
                                                    name="backToVersions" 
                                                    click={this.deselectVersion} 
                                                />
                                            </Link>
                                            {/* <Button className="fucsia" 
                                                style={{width: 200, marginBottom: 30, fontWeight: 700 }} 
                                                label={config.appTarget === 'admin' ? "Seleziona auto" : "Richiedila ora!"}
                                                name="orderNow" 
                                                click={this.openRequest}
                                            /> */}
                                        </div>
                                        <VersionFilter  
                                            items={this.state.versions || null} 
                                            filtersDefault={this.state.filtersDefault}
                                            change={this.change}
                                            filters={this.state.filters}
                                            checkAll={this.checkAll}
                                            versions={this.state.versions}
                                        />
                                        <Versions 
                                            versions={this.state.versions} 
                                            filters={this.state.filters} 
                                            click={this.click}
                                            selectVersion={this.openRequest}
                                            configure={this.configure}
                                            link={this.getVersionLinkUrl()}
                                        />
                                    </div>
                            }
                            
                            {this.props.selectedVersion? <Button className="fucsia" 
                                style={{width: 200, fontWeight: 700, marginBottom: 30}} 
                                label={config.appTarget === 'admin' ? "Seleziona auto" : "Richiedila ora!"}
                                name="orderNow" 
                                id={this.props.selectedVersion.versionUrlCode}
                                click={this.openRequest}
                            /> : null}
                        </div>
                    </div>
                </Grid>
            </Page>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ModelDetails));
//export default ModelDetails;