import './Page.css';
import React from 'react';

function Content(props) {
    return (
        <section className="App-content">
            {props.children}
        </section>
    )
}

export default Content;