import React from 'react';
import '../FormItems/FormItems.css';
import { Button } from '../FormItems';
import SearchableSelect from '../FormItems/SearchableSelect';

const ModalRequestForm = ({formData, click, change, checks, openChecks, showAll,  updateChecks, comuni}) => {
    //console.log("COMUNI LOAD",comuni)
    return (
        <div className="ModalRequest-container">
            <form method="post" onSubmit={(e)=>{e.preventDefault();console.log(e)}}>
                <div>
                    <div>
                        <label>Tipologia cliente*</label>
                        <select name="tipo" style={{}} onChange={change} value={formData.tipo || '0'}>
                            {formData.tipo === null || formData.tipo === "0" ? <option value="0">Seleziona</option>:null}
                            <option value="4">Società di capitali (spa, srl, srls, sapa società cooperativa, consorzio</option>
                            <option value="3">Società di persone (snc,sas,ss)</option>
                            <option value="1">Persona fisica</option>
                            <option value="2">Ditta individuale/libero professionista</option>
                        </select>
                    </div>
                </div>
                {
                    formData.tipo !== null ? (<div>
                        <div>
                        {
                                formData && formData.tipo !== "1" ? <div>
                                <label>Inizio attività*</label>
                                <select name="inizio_attivita" style={{}} onChange={change} defaultValue={formData.inizio_attivita}>
                                    <option>Seleziona</option>
                                    <option value="0">Più di due anni</option>
                                    <option value="1">Meno di due anni</option>
                                </select>
                            </div> : null
                        }
                        </div>
                        <div style={{display: 'grid', gridGap: 10, gridTemplateColumns: formData && formData.tipo !== "1" ? '1fr 1fr' : '1fr'}}>
                            
                            {
                                formData && formData.tipo !== "1" ? 
                                    <div style={{width: '100%'}}>
                                        <label>Ragione sociale*</label>
                                        <input name="ragione_sociale" onChange={change} value={formData.ragione_sociale} />
                                    </div> : null
                            }
                            <div style={{width: '100%'}}>
                                <label>{formData && formData.tipo !== "1" ? 'Partita IVA' : 'Codice fiscale'}</label>
                                <input name={formData && formData.tipo !== "1" ? 'p_iva':'cf'} onChange={change} value={formData[formData && formData.tipo !== "1" ? 'p_iva':'cf']} />
                            </div>
                        </div>
                        <div style={{display: 'grid', gridGap: 10, gridTemplateColumns: '1fr 1fr'}}>
                            <div style={{width: '100%'}}>
                                <label>Nome*</label>
                                <input name="nome" onChange={change} value={formData.nome} />
                            </div>
                            <div style={{width: '100%'}}>
                                <label>Cognome*</label>
                                <input name="cognome" onChange={change} value={formData.cognome} />
                            </div>
                        </div>
                        <div style={{display: 'grid', gridGap: 10, gridTemplateColumns: '1fr 1fr'}}>
                            <div style={{width: '100%'}}>
                                <label>Email*</label>
                                <input name="email" type="email" onChange={change} value={formData.email} />
                            </div>
                            <div style={{width: '100%'}}>
                                <label>Telefono*</label>
                                <input name="tel" type="phone" onChange={change} value={formData.tel} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <SearchableSelect 
                                    placeholder="Filtra comuni" 
                                    data={comuni} 
                                    name="comune" 
                                    change={change} 
                                    comune={formData.comune} 
                                    provincia={formData.provincia} 
                                />
                            </div>
                        </div>
                        <div style={{height: 20}} />
                        <label style={{fontSize: 11}}>
                            <input onChange={updateChecks} type="checkbox" checked={checks.all || checks.confirm} name="all" /> 
                            ACCETTO <a href="https://urcar.it/privacy-cookie-policy/" target="_privacy" style={{textDecoration:'none',cursor:'pointer'}}>l'informativa</a> e i consensi ai trattamenti. 
                            Non sono tutti obbligatori, puoi gestirli da <button style={{fontSize: 11, padding: 0}} onClick={(e)=> {e.preventDefault();openChecks();}} >qui</button>.
                        </label>
                        <p style={{fontSize: 10, fontStyle: 'italic', margin: 0}}></p>
                        {
                            showAll && 
                                <div className="consensi">
                                    <div className="text-left form-check">
                                        <h6>Finalità Commerciali (il consenso è necessario per poter erogare il servizio richiesto)</h6>
                                        <input onChange={updateChecks} required="" className="form-check-input" type="checkbox" name="confirm" value="confirm" id="confirm" checked={checks.confirm} />
                                        <label className="form-check-label" htmlFor="confirm">Esprimo il consenso al trattamento dei miei dati personali per finalità commerciali (connesse all’adempimento contrattuale dei servizi richiesti) così come indicate nell’informativa che precede. </label>
                                    </div>
                                    <div className="text-left form-check">
                                        <h6>Finalità marketing (il consenso è facoltativo)</h6>
                                        <input onChange={updateChecks} className="form-check-input" type="checkbox" value="confirm" name="confirm_b" id="confirm_b" checked={checks.confirm_b} />
                                        <label className="form-check-label" htmlFor="confirm_b">Esprimo il consenso al trattamento dei miei dati personali per finalità di marketing (pubblicitarie e promozionali) così come indicate nell’informativa che precede.</label>
                                    </div>
                                    <div className="text-left form-check">
                                        <h6><strong>Cessione dei dati a terzi (il consenso è facoltativo)</strong></h6>
                                        <input onChange={updateChecks} className="form-check-input" type="checkbox" value="confirm" name="confirm_c" id="confirm_c" checked={checks.confirm_c} />
                                        <label className="form-check-label" htmlFor="confirm_c">Esprimo il consenso al trattamento dei miei dati personali per finalità di “cessione dei dati a terzi” (aziende di marketing, di ricerche di mercato e di consulenza) così come indicate nell’informativa che precede.</label>

                                    </div>
                                    <div className="text-left form-check">
                                        <h6><strong>Controllo della qualità dei servizi (il consenso è facoltativo)</strong></h6>
                                        <input onChange={updateChecks} className="form-check-input" type="checkbox" value="confirm" name="confirm_d" id="confirm_d" checked={checks.confirm_d} />
                                        <label className="form-check-label" htmlFor="confirm_d">Esprimo il consenso al trattamento dei miei dati personali per finalità di “controllo della qualità dei servizi” così come indicate nell’informativa che precede.</label>
                                    </div>
                                </div>
                        }
                        <Button click={click} className="fucsia" label="Invia richiesta" />
                    </div>) : null
                }
            </form>
        </div>
    );
};

export default ModalRequestForm;