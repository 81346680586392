import React from 'react';
import './CarModel.css';
import {toMoney} from '../../Modules/Utils';
import { Link} from 'react-router-dom';


class CarModel extends React.Component {
    render(){
        const {model, summary} = this.props.item;
        return (
            <div className="Model-container">
                <Link to={"/versioni/" + model.makeUrlCode + '/' + model.submodelCommercialUrlCode}>
                    <div className="Model-content">
                        <div className="whiteback"></div> 
                        <div className="AutoCard">
                            <img alt={model.bodyType} className="mini-body" src={`https://cdn.urcar.it/assets/${model.bodyTypeUrlCode}.svg`} />
                            <img src={model.icons[0].icon200} className="img-models" alt="CarImage" /> 
                            <img src={model.makeLogo} className="mini-logo" alt={model.makeName} /> 
                            <h2>{model.submodelCommercialName}</h2> 
                            <h6>{summary.numVersions || null} version{parseInt(summary.numVersions)===1 ? 'e':'i'}</h6> 
                            <p className="subtitle">a partire da</p> 
                            <p className="price">{toMoney(summary.minPrice)}</p> 
                            <p className="extras">prezzo di listino</p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

}



export default CarModel;

