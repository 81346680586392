import React from 'react';
import {Button} from '../FormItems';

const SidebarStart = (props) => {
    switch(props.from){
        default:
        case 'make': 
            return (
                <div className="SidebarStart-container">
                    <h1>Configura la tua auto e trova la soluzione giusta per te!</h1>
                    <p>Da qui puoi configurare la tua partendo da una o più tipologia di carrozzeria. Potrai sempre specificare altre preferenze nelle pagine successive.</p>
                    <img alt="Urcar!" style={{height: '160px'}} src="https://cdn.urcar.it/assets/configurazione@2x.png" />
                
                    <Button 
                        style={{width: '250px',height: '60px', display: 'block', margin: '15px auto'}} 
                        className="fucsia" 
                        label={props.buttonLabel || "Mostra tutti i risultati"}
                        click={props.click || (() => console.log("clicked"))}
                        />
                </div>
            );
        case 'bodyType':
            return (
                <div className="SidebarStart-container">
                    <h1>Configura la tua auto e trova la soluzione giusta per te!</h1>
                    <p>Da qui puoi configurare la tua partendo da una o più tipologia di carrozzeria. Potrai sempre specificare altre preferenze nelle pagine successive.</p>
                    <img alt="Urcar!" style={{height: '160px'}} src="https://cdn.urcar.it/assets/configurazione@2x.png" />
                
                    <Button 
                        style={{width: '250px',height: '60px', display: 'block', margin: '15px auto'}} 
                        className="fucsia" 
                        label={props.buttonLabel || "Mostra tutti i risultati"}
                        click={props.click || (() => console.log("clicked"))}
                        />
                </div>
            );
    }
    
}

export default SidebarStart;