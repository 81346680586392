const bodyTypes = [{
    bodyType: 'berlina 2 vol',
    bodyTypeCode: 'HA',
    bodyTypeUrlCode: 'berlina-2-vol'
  },
  {
    bodyType: 'cabriolet',
    bodyTypeCode: 'CA',
    bodyTypeUrlCode: 'cabriolet'
  },
  {
    bodyType: 'coupé',
    bodyTypeCode: 'CO',
    bodyTypeUrlCode: 'coupe'
  },
  {
    bodyType: 'berlina 3 vol',
    bodyTypeCode: 'SA',
    bodyTypeUrlCode: 'berlina-3-vol'
  },
  {
    bodyType: 'SUV',
    bodyTypeCode: 'OD',
    bodyTypeUrlCode: 'suv'
  },
  {
    bodyType: 'roadster',
    bodyTypeCode: 'RO',
    bodyTypeUrlCode: 'roadster'
  },
  {
    bodyType: 'wagon',
    bodyTypeCode: 'ES',
    bodyTypeUrlCode: 'wagon'
  },
  {
    bodyType: 'monovolume',
    bodyTypeCode: 'FW',
    bodyTypeUrlCode: 'monovolume'
  },
  {
    bodyType: 'monov. comp.',
    bodyTypeCode: 'MM',
    bodyTypeUrlCode: 'monov-comp'
  },
  {
    bodyType: 'citycar',
    bodyTypeCode: 'MC',
    bodyTypeUrlCode: 'citycar'
  },
  {
    bodyType: 'crossover',
    bodyTypeCode: 'CR',
    bodyTypeUrlCode: 'crossover'
  },
  {
    bodyType: 'multispazio',
    bodyTypeCode: 'MS',
    bodyTypeUrlCode: 'multispazio'
  },
  {
    bodyType: 'targa',
    bodyTypeCode: 'TA',
    bodyTypeUrlCode: 'targa'
  },
  {
    bodyType: 'berlina hard top',
    bodyTypeCode: 'SH',
    bodyTypeUrlCode: 'berlina-hard-top'
  },
];

export default bodyTypes;