import React from 'react';
import { Grid } from '../Page';
import './Details.css';
import { Button } from '../FormItems';
import config from '../../Appconfig';

const VersionFilter = (props) => {
    const isCheched = (type, value) => {
        return props.filters ? props.filters[type].includes(value) : false 
    }

    const allChecked = () => {
        if(!props.filters) return false;
        let result = true;
        for(const i in props.filtersDefault){
            if(props.filtersDefault[i].length !== props.filters[i].length) result = false;
        }
        return result;
    }

    return(
        <div className="VersionFilter-wrapper" id="VersionFilter-wrapper">
            {config.appTarget === 'admin'? null : 
                <div>
                    <h2>Configura la tua auto</h2> 
                    <p>Tutti i dettagli sul prodotto partendo dalla sua storia, dall'idea, alla realizzazione. Info sulla casa madre e sul design</p>
                </div>
            }
            <div className="boxFilters">                
                <Button style={{float: 'right', marginTop: 0}} className="fucsia" click={props.checkAll || (e => {})} label={allChecked()? 'Deseleziona tutti' : 'Seleziona tutti'} />
                <h2>Filtra Caratteristiche</h2>
            </div>
            
            <Grid className="versionFilterUp">
                { props.filtersDefault.fuelType.length <= 1 ? null :
                    <div className="VersionFilter-container">
                        <div  className="filterUp filterFuel">
                            <header>Alimentazione</header>
                            {
                                props.filtersDefault.fuelType.sort().map(fuel => {
                                    return (
                                        <label key={fuel}>
                                            <input 
                                                type="checkbox" 
                                                className="fuelType" 
                                                name={fuel} 
                                                onChange={props.change}
                                                checked={isCheched('fuelType',fuel)}
                                                disabled={props.filtersDefault.fuelType.length === 1}
                                            /> {fuel} 
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                { props.filtersDefault.gearboxType.length <= 1 ? null :
                    <div className="VersionFilter-container">
                        <div className="filterUp filterGear">
                            <header>Cambio</header>
                        
                            {
                                props.filtersDefault.gearboxType.sort().map(gear => {
                                    return (
                                        <label key={gear}>
                                            <input 
                                                type="checkbox" 
                                                className="gearboxType" 
                                                name={gear} 
                                                onChange={props.change}
                                                checked={isCheched('gearboxType',gear)}
                                                disabled={props.filtersDefault.gearboxType.length === 1}
                                            /> {gear} 
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                { props.filtersDefault.traction.length <= 1 ? null :
                    <div className="VersionFilter-container">
                        <div className="filterUp filterTraction">
                            <header>Trazione</header>
                            
                            {
                                props.filtersDefault.traction.sort().map(traction => {
                                    return (
                                        <label key={traction}>
                                            <input 
                                                type="checkbox" 
                                                className="traction" 
                                                name={traction} 
                                                onChange={props.change}
                                                checked={isCheched('traction',traction)}
                                                disabled={props.filtersDefault.traction.length === 1}
                                            /> {traction} 
                                        </label>
                                        )
                                })
                            }
                            
                        </div>
                    </div>
                }

                { props.filtersDefault.engine.length <= 1 ? null :
                    <div className="VersionFilter-container">
                        <div className="filterUp filterEngine">
                            <header>Cilindrata</header>
                            
                            {
                                props.filtersDefault.engine.sort().map(engine => {
                                    return (
                                        <label key={engine}>
                                            <input 
                                                type="checkbox" 
                                                className="engine" 
                                                name={engine} 
                                                onChange={props.change}
                                                checked={isCheched('engine',engine)}
                                                disabled={props.filtersDefault.engine.length === 1}
                                            /> {engine} 
                                        </label>
                                        )
                                })
                            }
                            
                        </div>
                    </div>
                }

                { props.filtersDefault.trim.length <= 1 ? null :
                    <div className="VersionFilter-container">
                        <div className="filterUp filterTrim">
                            <header>Allestimento</header>
                            
                            {
                                props.filtersDefault.trim.sort().map(trim => {
                                    return (
                                        <label key={trim}>
                                            <input 
                                                type="checkbox" 
                                                className="trim" 
                                                name={trim} 
                                                onChange={props.change}
                                                checked={isCheched('trim',trim)}
                                                disabled={props.filtersDefault.trim.length === 1}
                                            /> {trim === '-' ? 'BASE':trim} 
                                        </label>
                                        )
                                })
                            }
                            
                        </div>
                    </div>
                }

                { props.filtersDefault.version.length <= 1 ? null :
                    <div className="VersionFilter-container">
                        <div className="filterUp filterTrim">
                            <header>Versione</header>
                            
                            {
                                props.filtersDefault.version.sort().map(version => {
                                    return (
                                        <label key={version}>
                                            <input 
                                                type="checkbox" 
                                                className="version" 
                                                name={version} 
                                                onChange={props.change}
                                                checked={isCheched('version',version)}
                                                disabled={props.filtersDefault.version.length === 1}
                                            /> {version} 
                                        </label>
                                        )
                                })
                            }
                            
                        </div>
                    </div>
                }
            </Grid>
        </div>
    )
}

export default VersionFilter;