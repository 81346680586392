import React from 'react';
import './Details.css';
import SidebarHeader from '../Sidebars/SidebarHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight,faCaretUp,faCaretDown } from '@fortawesome/free-solid-svg-icons'
import toMoney from '../../Modules/Utils';
import { Button } from '../FormItems';

const Equipments = (props) => {
    const {equipments} = props;
    let optionalsAvailable = 0;
    const atoggle = () => {
        props.toggle('equipments')
    }
    const btoggle = (e) => {
        console.log('subtoggle', e.target)
        props.toggle('sub:'+e.target.id)
    }
    const { subtoggles } = props;

    const optionalCount = {}
    
    for(let key in equipments) {
        if(key === 'makeName') continue;
        let keyopts = 0;
        for(const obj in equipments[key]){
            if(Array.isArray(equipments[key][obj])){
                const x = equipments[key][obj].filter(o => o.type !== "STANDARD").length;
                keyopts += x;
                optionalsAvailable += x;
            }
        } 
        optionalCount[key] = keyopts;
    };
    return(
        <section className="equipments">
            {
                props.header ?       
                    <SidebarHeader toggle={atoggle} toggled={props.toggled} containerClass="VersionDetails-header">
                        <div style={{fontSize: 22, letterSpacing: -0.6}}>Equipaggiamenti </div>
                        <div></div>
                    </SidebarHeader>
                    : null
            }
            {
                !props.toggled ? 
                    <div>
                        
                        <div className="Equipments-container">
                            {
                                props.showOptionals? 
                                    <div className="optionalsNum">
                                        Selezionati {props.optionals.length || 0} di {optionalsAvailable || 0} optional
                                        {
                                            props.optionals.length && props.optionals.length>0 ? 
                                                <div><Button className="fucsia" click={props.removeAllOptionals} label="Rimuovi tutti gli optional" /></div> 
                                                : null
                                            }
                                    </div>
                                    : null
                            }
                            {
                                Object.keys(props.equipments).map(key => {
                                    return  (
                                        key === 'makeName' || !key || (props.showOptionals && optionalCount[key]===0) ?  
                                            null
                                        :
                                        <div className="EquipCategory" key={key} >
                                            <div className="titolo-button" style={{backgroundColor: 'rgb(245, 246, 248)', height: 60}}>
                                                <div className="h2-div subHeader">
                                                    <button onClick={btoggle} id={key}>
                                                        <FontAwesomeIcon icon={true? faCaretUp : faCaretDown} />
                                                    </button>
                                                    <h2>{key} <small>({optionalCount[key]})</small></h2>
                                                </div>
                                            </div>
                                            <ul>
                                                {
                                                    !subtoggles[key] && Object.keys(props.equipments[key]).map(obj => {
                                                        if(!props.equipments[key][obj].some(x => x.type !== "STANDARD") && props.showOptionals) return null;
                                                        return (
                                                            <li className="EquipSubCategory" key={obj}>
                                                                <h5>{obj}</h5>
                                                                <ul>
                                                                    {
                                                                        props.equipments[key][obj].map((opt,index) => {
                                                                            if(opt.type === "STANDARD" && props.showOptionals) return null;
                                                                            if(opt.type !== "STANDARD" && !props.showOptionals) return null;
                                                                            return (
                                                                                <li 
                                                                                    key={index} 
                                                                                    className={(opt.type === "STANDARD" ? 'standard':'optional') + (props.optionals.some(i => i.idEquipment===opt.idEquipment) ? ' selected' : '')} 
                                                                                    id={'opt-' + opt.idEquipment}
                                                                                    data-obj={JSON.stringify(opt)} 
                                                                                    onClick={opt.type === "STANDARD" ? null : props.click}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faCaretRight} /> 
                                                                                    <span style={{marginLeft: 10, pointerEvents: 'none'}}>{opt.description} 
                                                                                        
                                                                                        {opt.manufacturerCode && <span style={{display:'inline-block',marginLeft: 10}}>({opt.manufacturerCode})</span>}
                                                                                    </span>
                                                                                    <div className="EquipOptionalLabel" style={{
                                                                                        top: opt.type === "STANDARD" ? 10 : 3,
                                                                                    }}>
                                                                                        {
                                                                                            opt.type === "STANDARD" ?
                                                                                                <em>di serie</em>
                                                                                            :
                                                                                                <strong>optional {opt.basePrice ? <em style={{fontSize: 11,textAlign: 'right',display:'block'}}>{toMoney(opt.basePrice)}</em>:null}</strong>

                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : null
            }
        </section>
    )
}

export default Equipments;