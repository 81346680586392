import './Page.css';
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import {Helmet} from 'react-helmet';

function Page(props) {
    return (
        <div className="Page-component">
            <Helmet>
                <title>{props.metaTitle || 'Urcar! Configura la tua vettura.'}</title>
                <meta name="description" content={props.metaDescription || "Configura la tua auto e scopri come ottenerla scontata con Urcar!"} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={props.metaUrl || window.location.href.substring(0, window.location.href.indexOf('#') - 1)} />
                <meta property="og:title" content={props.metaTitle || 'Urcar! Configura la tua vettura.'} />
                <meta property="og:description" content={props.metaDescription || "Configura la tua auto e scopri come ottenerla scontata con Urcar!"} />
                <meta property="og:image" content={props.metaImage || "https://cdn.urcar.it/assets/urcar_logo.svg"} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={props.metaUrl || window.location.href.substring(0, window.location.href.indexOf('#') - 1)} />
                <meta property="twitter:title" content={props.metaTitle || 'Urcar! Configura la tua vettura.'} />
                <meta property="twitter:description" content={props.metaDescription || "Configura la tua auto e scopri come ottenerla scontata con Urcar!"} />
                <meta property="twitter:image" content={props.metaImage || "https://cdn.urcar.it/assets/urcar_logo.svg"} />
            </Helmet>
            
            <Header {...props} />

            <Content>
                {props.children}
            </Content>
            
            <Footer />
        </div>
    );
}

export default Page;