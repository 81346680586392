const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
});

export const toMoney = (data) => {
    if(Number.isNaN(parseFloat(data)) || Number.isNaN(parseInt(data))) return data;
    let price = formatter.format(data);
    price = price.replace("€","");
    return `€ ${price.trim()}`;
}

export const sortObject = (o) => {
    let sorted = {};
    let key, a = [];

    for (key in o) {
        if (o.hasOwnProperty(key)) {
            a.push(key);
        }
    }
    a.sort();
    for (key = 0; key < a.length; key++) {
        sorted[a[key]] = o[a[key]];
    }
    return sorted;
}

export const getBgLightness = (hexinput) => {
    const rgb = hexToRgb(hexinput)
    if(!rgb) return 0; 
    const o = Math.round(((parseInt(rgb.r) * 299) +
                      (parseInt(rgb.g) * 587) +
                      (parseInt(rgb.b) * 114)) / 1000);
    return (o > 125) ? 0 : 1;
}

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


export default toMoney;