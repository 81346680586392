const makes = [{
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/abarth-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/abarth-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/abarth-cover-1920x280.jpg"
    },
    makeId: 1,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/abarth.svg",
    makeName: "Abarth",
    makeUrlCode: "abarth"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/alfa-romeo-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/alfa-romeo-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/alfa-romeo-cover-1920x280.jpg"
    },
    makeId: 2,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/alfa-romeo.svg",
    makeName: "Alfa Romeo",
    makeUrlCode: "alfa-romeo"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/audi-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/audi-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/audi-cover-1920x280.jpg"
    },
    makeId: 4,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/audi.svg",
    makeName: "Audi",
    makeUrlCode: "audi"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/bmw-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/bmw-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/bmw-cover-1920x280.jpg"
    },
    makeId: 6,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/bmw.svg",
    makeName: "BMW",
    makeUrlCode: "bmw"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/citroen-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/citroen-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/citroen-cover-1920x280.jpg"
    },
    makeId: 9,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/citroen.svg",
    makeName: "Citroën",
    makeUrlCode: "citroen"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/dacia-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/dacia-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/dacia-cover-1920x280.jpg"
    },
    makeId: 10,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/dacia.svg",
    makeName: "Dacia",
    makeUrlCode: "dacia"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/dr-automobiles-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/dr-automobiles-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/dr-automobiles-cover-1920x280.jpg"
    },
    makeId: 11,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/dr-automobiles.svg",
    makeName: "DR AUTOMOBILES",
    makeUrlCode: "dr-automobiles"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/ds-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/ds-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/ds-cover-1920x280.jpg"
    },
    makeId: 104,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/ds.svg",
    makeName: "DS",
    makeUrlCode: "ds"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/ferrari-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/ferrari-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/ferrari-cover-1920x280.jpg"
    },
    makeId: 12,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/ferrari.svg",
    makeName: "Ferrari",
    makeUrlCode: "ferrari"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/fiat-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/fiat-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/fiat-cover-1920x280.jpg"
    },
    makeId: 13,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/fiat.svg",
    makeName: "FIAT",
    makeUrlCode: "fiat"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/ford-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/ford-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/ford-cover-1920x280.jpg"
    },
    makeId: 15,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/ford.svg",
    makeName: "Ford",
    makeUrlCode: "ford"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/great-wall-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/great-wall-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/great-wall-cover-1920x280.jpg"
    },
    makeId: 16,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/great-wall.svg",
    makeName: "Great Wall",
    makeUrlCode: "great-wall"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/honda-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/honda-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/honda-cover-1920x280.jpg"
    },
    makeId: 17,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/honda.svg",
    makeName: "Honda",
    makeUrlCode: "honda"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/hyundai-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/hyundai-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/hyundai-cover-1920x280.jpg"
    },
    makeId: 18,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/hyundai.svg",
    makeName: "Hyundai",
    makeUrlCode: "hyundai"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/infiniti-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/infiniti-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/infiniti-cover-1920x280.jpg"
    },
    makeId: 19,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/infiniti.svg",
    makeName: "Infiniti",
    makeUrlCode: "infiniti"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/jaguar-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/jaguar-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/jaguar-cover-1920x280.jpg"
    },
    makeId: 20,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/jaguar.svg",
    makeName: "Jaguar",
    makeUrlCode: "jaguar"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/jeep-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/jeep-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/jeep-cover-1920x280.jpg"
    },
    makeId: 21,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/jeep.svg",
    makeName: "Jeep",
    makeUrlCode: "jeep"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/kia-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/kia-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/kia-cover-1920x280.jpg"
    },
    makeId: 22,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/kia.svg",
    makeName: "KIA",
    makeUrlCode: "kia"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/lamborghini-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/lamborghini-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/lamborghini-cover-1920x280.jpg"
    },
    makeId: 24,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/lamborghini.svg",
    makeName: "Lamborghini",
    makeUrlCode: "lamborghini"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/lancia-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/lancia-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/lancia-cover-1920x280.jpg"
    },
    makeId: 25,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/lancia.svg",
    makeName: "Lancia",
    makeUrlCode: "lancia"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/land-rover-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/land-rover-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/land-rover-cover-1920x280.jpg"
    },
    makeId: 26,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/land-rover.svg",
    makeName: "Land Rover",
    makeUrlCode: "land-rover"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/lexus-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/lexus-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/lexus-cover-1920x280.jpg"
    },
    makeId: 27,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/lexus.svg",
    makeName: "Lexus",
    makeUrlCode: "lexus"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/mahindra-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/mahindra-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/mahindra-cover-1920x280.jpg"
    },
    makeId: 29,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/mahindra.svg",
    makeName: "Mahindra",
    makeUrlCode: "mahindra"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/maserati-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/maserati-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/maserati-cover-1920x280.jpg"
    },
    makeId: 30,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/maserati.svg",
    makeName: "Maserati",
    makeUrlCode: "maserati"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/mazda-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/mazda-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/mazda-cover-1920x280.jpg"
    },
    makeId: 32,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/mazda.svg",
    makeName: "Mazda",
    makeUrlCode: "mazda"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/mercedes-benz-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/mercedes-benz-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/mercedes-benz-cover-1920x280.jpg"
    },
    makeId: 34,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/mercedes-benz.svg",
    makeName: "Mercedes-Benz",
    makeUrlCode: "mercedes-benz"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/mini-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/mini-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/mini-cover-1920x280.jpg"
    },
    makeId: 36,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/mini.svg",
    makeName: "MINI",
    makeUrlCode: "mini"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/mitsubishi-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/mitsubishi-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/mitsubishi-cover-1920x280.jpg"
    },
    makeId: 37,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/mitsubishi.svg",
    makeName: "Mitsubishi",
    makeUrlCode: "mitsubishi"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/nissan-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/nissan-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/nissan-cover-1920x280.jpg"
    },
    makeId: 39,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/nissan.svg",
    makeName: "Nissan",
    makeUrlCode: "nissan"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/opel-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/opel-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/opel-cover-1920x280.jpg"
    },
    makeId: 40,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/opel.svg",
    makeName: "Opel",
    makeUrlCode: "opel"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/peugeot-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/peugeot-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/peugeot-cover-1920x280.jpg"
    },
    makeId: 41,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/peugeot.svg",
    makeName: "Peugeot",
    makeUrlCode: "peugeot"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/porsche-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/porsche-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/porsche-cover-1920x280.jpg"
    },
    makeId: 42,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/porsche.svg",
    makeName: "Porsche",
    makeUrlCode: "porsche"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/renault-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/renault-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/renault-cover-1920x280.jpg"
    },
    makeId: 43,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/renault.svg",
    makeName: "Renault",
    makeUrlCode: "renault"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/seat-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/seat-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/seat-cover-1920x280.jpg"
    },
    makeId: 45,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/seat.svg",
    makeName: "SEAT",
    makeUrlCode: "seat"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/skoda-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/skoda-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/skoda-cover-1920x280.jpg"
    },
    makeId: 46,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/skoda.svg",
    makeName: "ŠKODA",
    makeUrlCode: "skoda"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/smart-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/smart-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/smart-cover-1920x280.jpg"
    },
    makeId: 47,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/smart.svg",
    makeName: "smart",
    makeUrlCode: "smart"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/ssangyong-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/ssangyong-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/ssangyong-cover-1920x280.jpg"
    },
    makeId: 48,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/ssangyong.svg",
    makeName: "SsangYong",
    makeUrlCode: "ssangyong"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/subaru-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/subaru-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/subaru-cover-1920x280.jpg"
    },
    makeId: 49,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/subaru.svg",
    makeName: "Subaru",
    makeUrlCode: "subaru"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/suzuki-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/suzuki-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/suzuki-cover-1920x280.jpg"
    },
    makeId: 50,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/suzuki.svg",
    makeName: "Suzuki",
    makeUrlCode: "suzuki"
  },
  /*{
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/tesla-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/tesla-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/tesla-cover-1920x280.jpg"
    },
    makeId: 52,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/tesla.svg",
    makeName: "Tesla",
    makeUrlCode: "tesla"
},*/
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/toyota-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/toyota-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/toyota-cover-1920x280.jpg"
    },
    makeId: 53,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/toyota.svg",
    makeName: "Toyota",
    makeUrlCode: "toyota"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/volkswagen-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/volkswagen-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/volkswagen-cover-1920x280.jpg"
    },
    makeId: 54,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/volkswagen.svg",
    makeName: "Volkswagen",
    makeUrlCode: "volkswagen"
  },
  {
    makeCovers: {
      cover500: "https://cdn.drivek.it/cars/make/cover/volvo-cover-500x280.jpg",
      cover900: "https://cdn.drivek.it/cars/make/cover/volvo-cover-900x280.jpg",
      cover1920: "https://cdn.drivek.it/cars/make/cover/volvo-cover-1920x280.jpg"
    },
    makeId: 55,
    makeLogo: "https://cdn.drivek.it/cars/make/full-logo/volvo.svg",
    makeName: "Volvo",
    makeUrlCode: "volvo"
  }
];


export default makes;