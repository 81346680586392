import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import * as serviceWorker from './serviceWorker';

import routes from './routes';
import config from './Appconfig';
//import Page404 from './Containers/Page404';

if(config.environment === 'live'){
    console.log('Configura la tua auto con URCAR!',config.appVersion);
    window.console.log = (...params) => {};
    window.console.error = (...params) => {};
    window.console.warning = (...params) => {};
} else {
    console.log('dev Environment')
}

const compiledRoutes = () => {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            render={route.render}
                        />
                    ))}
                    {/* <Route component={Page404} /> */}
                </Switch>
            </Router>
        </Provider>
    )
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(compiledRoutes(), rootElement)
} else {
    ReactDOM.render(compiledRoutes(), rootElement);
}

serviceWorker.unregister();
