import './Page.css';
import React from 'react';

function Footer(props) {
    return (
        <footer className="App-footer">
            <img src={'https://cdn.urcar.it/assets/urcar_logo_white.svg'} className="App-logo" alt="logo" />
        </footer>
    )
}

export default Footer;