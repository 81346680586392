import React from 'react';
import './Selectors.css';
//import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import {toMoney} from '../../Modules/Utils';
import SidebarHeader from '../Sidebars/SidebarHeader';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
/*const Handle = Slider.Handle;

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
};
*/
class RangeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarToggle: true
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        console.log(this.state.sidebarToggle ? 'show': 'hide')
        this.setState({sidebarToggle: !this.state.sidebarToggle});
    }

    render(){
        const { filter,sidebar,change} = this.props;
        const {minPrice,maxPrice} = filter;
        return (
            <div className="Range-selector" id={this.props.id}>
                {
                        sidebar ? 
                            <SidebarHeader notoggle={true} containerClass="VersionDetails-header">
                                Prezzo di listino
                                <br />
                                <div>
                                    <small style={{fontWeight: 400}}>
                                        da {minPrice <= 5000 ? 'meno di ':null} <strong>{toMoney(minPrice)}</strong> a {maxPrice >= 100000 ? 'oltre ' : null} <strong>{toMoney(maxPrice)}</strong>
                                    </small>
                                </div>
                                
                            </SidebarHeader>
                            :
                            null
                }
                <div className={"Range-container " + (sidebar ? "sidebar "  : " ") + (this.state.sidebarToggle ? 'toggled' : '')}>
                    <Range
                        min={5000}
                        max={100000}
                        step={1000}
                        onChange={change}
                        defaultValue={[5000,100000]}
                        pushable={true}
                        tipFormatter={value => `${toMoney(value)}`}
                    />
                </div>
            </div>
        )
    }
}

export default RangeSelector;