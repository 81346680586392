import React, {Component} from 'react';
import Page, {Grid} from '../../Components/Page';
import SidebarFilter from '../../Components/Sidebars/SidebarFilter';
import store from '../../store/store';
import actions from '../../store/actions';
import './ModelResults.css';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import Api from '../../Modules/Api';
import {makes} from '../../assets/data';
import CarModel from '../../Components/CarModel/CarModel';
import ResultsHeadbar from '../../Components/ResultsHeadbar/ResultsHeadbar';
import Action from '../../store/actions';
import AnimatedIcon from '../../Components/AnimatedIcon/AnimatedIcon';

const mapStateToProps = (state, ownProps) => {
    console.log("statemap",state, ownProps);
    return {
        bodyType: state.cars.bodyType,
        make: state.cars.make,
        models: state.cars.database,
        offers: state.offers.data,
    };
}

class ModelResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            filters: {
                bodyTypeUrlCode: [],
                makeUrlCode: [],
                maxPrice: 100000,
                minPrice: 5000,
                submodelId: null,
            },
            filteredItems: [],
            savedSearches: [],
            liked: [],
            offers: [],
            similarOffers: 0,
            orderby: 'price',
            loading: false,
            ready: false
        }
        this.click = this.click.bind(this);
        this.change = this.change.bind(this);
        this.orderSelect = this.orderSelect.bind(this);
        this.filterOffers = this.filterOffers.bind(this);

        this.api = new Api();
    }
    
    async componentDidMount(){
        window.scrollTo(0,0)
        
        if(this.props.models.length === 0){
            this.setState({loading: true});
            await this.api.loadAll()
                .then(models => {
                    this.setState({models: models,loading: false });
                    store.dispatch({
                        type: Action.DATA_COMPLETED,
                        payload: models
                    });
                    this.api.getOffers().then(r => {
                        store.dispatch({type: actions.OFFERS_COMPLETED, payload: r})
                        this.filterOffers()
                    })
                })
                .catch(err => console.error(err));
            
        }

        const {searchString} = this.props.match.params;
        let filters = {
            bodyTypeUrlCode: [],
            makeUrlCode: [],
            maxPrice: 100000,
            minPrice: 5000,
            submodelId: null,
        };
        if(searchString) {
            const vars = searchString.split(',');
            filters = this.parseVars(vars);
        }

        this.setState({filters: filters, ready: true, filteredItems: this.filterItems(filters) });
    }

    orderSelect(e) {
        this.setState({orderby: e.target.value});
    }

    change(e) {
        let [min,max] = e;
        if(min === null) min = 5000;
        if(max === null) max = 100000;
        this.setState(old => {
            old.filters.minPrice = min;
            old.filters.maxPrice = max;
            return old;
        })
    }

    click(e) {
        e.preventDefault();
        const {className,id} = e.target;
        const {filters} = this.state;
        if(className.match(new RegExp('MakeSelector-item','g'))){
            const index = filters.makeUrlCode.indexOf(id);
            if(index > -1) {
                filters.makeUrlCode.splice(index,1);
            } else {
                filters.makeUrlCode.push(id);
            }
            store.dispatch({ type: actions.ADD_MAKE,  payload: id });
        } else if(className.match(new RegExp('BodyTypeSelector-item','g'))) {
            const index = filters.bodyTypeUrlCode.indexOf(id);
            if(index > -1) {
                filters.bodyTypeUrlCode.splice(index,1);
            } else {
                filters.bodyTypeUrlCode.push(id);
            }
            store.dispatch({ type: actions.ADD_BODY,  payload: id });
        }
        this.filterOffers()
    }

    filterItems(filters) {
        let out = [].concat(this.props.models);
        const allowFilters = ['makeUrlCode','bodyTypeUrlCode'];

        for(const property in filters) {
            if(allowFilters.includes(property)) {
                out = out.filter( item => {
                    return filters[property].length === 0 || filters[property].indexOf(item.model[property]) > -1;
                });
            }
        }
        out = out.filter(item => {
            return (filters.minPrice <= 5000 || (item.model.minPrice || item.summary.minPrice) >= filters.minPrice) 
                    && (filters.maxPrice >= 100000 || (item.model.minPrice || item.summary.minPrice) <= filters.maxPrice)
        })
        if(out && out.length) console.log('cars filtered', out);
        return out.slice(0,100);
    }

    sortItems(data, orderby) {
        switch(orderby){
            case 'price':
            default:
                return data.sort((a,b) => {
                    if(a.summary.minPrice===b.summary.minPrice) return 0;
                    return a.summary.minPrice>b.summary.minPrice? 1 : -1;
                });
            case '-price':
                return data.sort((a,b) => {
                    if(a.summary.minPrice===b.summary.minPrice) return 0;
                    return a.summary.minPrice<b.summary.minPrice? 1 : -1;
                });
        }
    }

    filterOffers() {
        let foundOffers = 0;
        if(this.props.offers) {
            this.props.offers.forEach(i => {
                if(this.state.filters.makeUrlCode.includes(i.makeUrlCode)){
                    foundOffers++;
                }
            })
            this.setState({similarOffers: foundOffers})
        }
    }

    render() {
        const filtered = this.filterItems(this.state.filters);
        const models = this.sortItems(filtered,this.state.orderby);
        return (
            <Page title="Risultati">
                
                <Grid>
                    <div className="Results-page-left" style={{background:'#F7F7F7',padding:'40px'}}>
                        <SidebarFilter click={this.click} filters={this.state.filters} change={this.change} />
                    </div>
                    <div className="Results-page-right">
                        <ResultsHeadbar 
                            results={filtered.length || 0} 
                            similarOffers={this.state.similarOffers} 
                            change={this.orderSelect} 
                            selected={this.state.orderby}
                        />
                        <Grid className="resultsGrid">
                            {
                                this.state.loading ? 
                                    <div>
                                        <AnimatedIcon />
                                    </div>
                                :
                                    !models || models.length === 0 ?
                                        <div>
                                            Non ci sono modelli con queste caratteristiche
                                        </div>
                                        
                                    :
                                        (models).map( item => {
                                            return (
                                                <CarModel key={item.model.submodelId} item={item} />
                                            )
                                        })
                            }
                        </Grid>
                    </div>
                </Grid>
            </Page>
        )
    }

    parseVars(vars) {
        const data = {
            bodyTypeUrlCode:[], 
            makeUrlCode:[],
            maxPrice: 100000,
            minPrice: 5000,
            subModelId: "",

        };
        console.log('v',vars)
        for(const i in vars){
            if(vars[i].indexOf('*') > -1) {
                console.log("found body",vars[i])
                data.bodyTypeUrlCode.push(vars[i].replace('*',''));
            } else if(vars[i].indexOf('$') > -1) {
                console.log("found price",vars[i])
                const prices = vars[i].replace('$','').split('|');
                data.minPrice = prices[0] || 5000;
                data.maxPrice = prices[1] || 100000;
            } else {
                if(makes.map(i => i.makeUrlCode).includes(vars[i])) {
                    console.log("found make",vars[i])
                    data.makeUrlCode.push(vars[i]);
                } else {
                    console.log(vars[i],'not found')
                }
            }
        }
        return data;
    }
}

export default connect(mapStateToProps)(withRouter(ModelResults));
//export default ModelResults;