import React, {Component} from 'react';
import Page from '../../Components/Page';

class Login extends Component {

    render() {
        return (
            <Page title="Login">
                
            </Page>
        )
    }
}

export default Login;