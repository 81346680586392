import Actions from '../actions';

const initialState = {
    selectedModel: null,
    selectedVersion: null,
    optionals: [],
    color: null
  };
  
export default function modelsReducer(state = initialState, action) {
    switch(action.type) {
        case Actions.VERSIONS_COMPLETED:
                return {
                    ...state,
                    selectedModel: action.payload
                }
        case Actions.VERSION_SELECTED:
                return {
                    ...state,
                    selectedVersion: action.payload
                }
        case Actions.OPTIONALS_CHANGED:
            return {
                ...state,
                optionals: action.payload
            }
        case Actions.COLOR_CHANGED:
            return {
                ...state,
                color: action.payload
            }
        default: break;
    }
    return state;
}