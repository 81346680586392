
const cardata = {
    bodyCount: {
        'berlina-2-vol': 109,
        'citycar': 16,
        'monov-comp': 33,
        'coupe': 39,
        'roadster': 10,
        'berlina-3-vol': 37,
        'targa': 2,
        'crossover': 27,
        'cabriolet': 31,
        'wagon': 47,
        'suv': 109,
        'coupe-cabrio': 1,
        'monovolume': 11,
        'multispazio': 1,
        'berlina-hard-top': 1
    },
    modelsCount: {
        'abarth': 5,
        'citroen': 15,
        'peugeot': 18,
        'ferrari': 5,
        'lamborghini': 2,
        'nissan': 11,
        'mitsubishi': 8,
        'subaru': 6,
        'kia': 19,
        'bmw': 29,
        'honda': 7,
        'porsche': 11,
        'audi': 43,
        'toyota': 17,
        'fiat': 17,
        'volkswagen': 23,
        'suzuki': 9,
        'ford': 28,
        'dacia': 7,
        'great-wall': 1,
        'land-rover': 10,
        'ds': 4,
        'opel': 14,
        'ssangyong': 5,
        'lexus': 10,
        'hyundai': 13,
        'maserati': 5,
        'mercedes-benz': 30,
        'infiniti': 6,
        //tesla: 3,
        'dr-automobiles': 5,
        'jaguar': 9,
        'mazda': 8,
        'renault': 17,
        'mahindra': 2,
        'volvo': 11,
        'alfa-romeo': 4,
        'seat': 8,
        'jeep': 6,
        'lancia': 1,
        'smart': 6,
        'skoda': 11,
        'mini': 5
    }
}

export default cardata;