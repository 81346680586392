import './Selectors.css';
import '../Sidebars/Sidebars.css';
import React from 'react';
import {makes} from '../../assets/data';
import { Link } from 'react-router-dom';
import SidebarHeader from '../Sidebars/SidebarHeader';


const MakeSelectorItem = (props) => {
    const selClass = props.selected ? 'selected' : '';
    return (
        <div className={"MakeSelector-item " + selClass} onClick={props.click} id={props.make}>
            <img src={props.img} alt={props.makeName} title={props.makeName} />
        </div>
    );
}

class MakeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            sidebarToggle: true,
        }
        this.createChildren = this.createChildren.bind(this);
        this.click = this.click.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    
    click(e) {
        this.props.click(e);
    }

    toggle() {
        console.log(this.state.sidebarToggle ? 'show': 'hide')
        this.setState({sidebarToggle: !this.state.sidebarToggle});
    }

    createChildren(){
        const makesItems = [];
        for(const i in makes){
            const item = (!this.props.sidebar) ?
                <Link key={makes[i].makeUrlCode} 
                    to={'/modelli/' + makes[i].makeUrlCode} >
                    <MakeSelectorItem 
                        key={makes[i].makeName} 
                        img={makes[i].makeLogo} 
                        make={makes[i].makeUrlCode} 
                    />
                </Link> 
                :
                <MakeSelectorItem 
                    key={makes[i].makeName}
                    img={makes[i].makeLogo}
                    make={makes[i].makeUrlCode}
                    click={this.click}
                    selected={this.props.filter.indexOf(makes[i].makeUrlCode)>-1}
                />
            
            makesItems.push(item);
        }
        return makesItems;
    }
    
    render(){
        return (
            <div className="Make-selectorContainer">
                {
                    this.props.sidebar ? 
                        <SidebarHeader toggle={this.toggle} toggled={this.state.sidebarToggle} containerClass="VersionDetails-header">
                            Marca 
                            <div>
                            {
                                this.props.filter.map(i => <small key={i}>{i}</small>)
                            }
                            </div>
                        </SidebarHeader>
                        :
                        null
                }
                <div className={"Make-selector " + (this.props.sidebar ? "sidebar "  : " ") + (this.props.sidebar && this.state.sidebarToggle ? 'toggled' : '')}>
                    {this.createChildren()}
                </div>
            </div>
            
        );
    }
}

export default MakeSelector;