import React from 'react';

import MakeSelector from '../Selectors/MakeSelector';
import BodyTypeSelector from '../Selectors/BodyTypeSelector';
import RangeSelector from '../Selectors/RangeSelector';


const SidebarFilter = (props) => {
    return (
        <div className="SidebarFilter-container">
            <MakeSelector sidebar={true} click={props.click} filter={props.filters.makeUrlCode} />
            <BodyTypeSelector sidebar={true} click={props.click} filter={props.filters.bodyTypeUrlCode} />
            <RangeSelector sidebar={true} change={props.change} filter={{minPrice:props.filters.minPrice,maxPrice:props.filters.maxPrice}} />
        </div>
    );
}

export default SidebarFilter;