import React, {Component} from 'react';
import Page, {Grid} from '../../Components/Page';
import MakeSelector from '../../Components/Selectors/MakeSelector';
import SidebarStart from '../../Components/Sidebars/SidebarStart';
import './Starters.css';
import {connect} from 'react-redux';
import {Redirect,withRouter} from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {  
    return {
        database: state.cars.database,
        make: state.cars.make
    };
}

class Make extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goto: ''
        }
    }
    
    goToSelected(val) {
        this.setState({goto: '/modelli'})
    }
    componentDidMount() {
        //console.log('cars',this.props.database || 'none')
    }
    render() {
        if(!this.props.database || this.props.database.length === 0) {
            return <Redirect to="/" />;
        } 
        if(this.state.goto) return <Redirect to={this.state.goto} />;
        return (
            <Page title="Scegli la marca">
                <Grid>
                    <div className="Entry-page-left" style={{background:'#F7F7F7',padding:'40px'}}>
                        <SidebarStart from="make" click={this.goToSelected.bind(this)} />
                    </div>
                    <div className="Entry-page-right">
                        <MakeSelector />
                    </div>
                </Grid>
            </Page>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Make));
//export default Make;