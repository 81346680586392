import rootReducer from './reducer';
import { createStore, /*applyMiddleware*/ } from "redux";

/* 
import createSagaMiddleware from 'redux-saga';
import mySaga from '../sagas/sagas';
 */

//const sagaMiddleware = createSagaMiddleware();
//const store = createStore(rootReducer,applyMiddleware(sagaMiddleware));

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const store = createStore(rootReducer,preloadedState);

//sagaMiddleware.run(mySaga);

export default store;