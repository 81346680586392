import React from 'react';

const Emissions = (props) => {
    return (
        <section className="emissions">
            <div className="DetailsEmissions-content">
                <ul>
                    <li>
                        <p className="DetailsSpecListItem">Omologazione</p> 
                        <p className="DetailsSpecListItemData">
                        <span>{props.item.emissions.classDescription} </span></p>
                    </li>
                        <li>
                        <p className="DetailsSpecListItem">Emissione</p> 
                        <p className="DetailsSpecListItemData">
                            <span>{props.item.emissions.co2} </span> g/km</p> 
                    </li>

                    {
                        (props.item.tankCapacity && props.item.consumption && props.item.consumption.combined) ?
                            <li>
                            <p className="DetailsSpecListItem">Autonomia</p> 
                                <p className="DetailsSpecListItemData">
                                    <span >{
                                        Math.round((props.item.tankCapacity/parseFloat(props.item.consumption.combined))*100)
                                    }</span> km</p> 
                            </li>
                            : null
                    }
                    
                    <li>
                        <p className="DetailsSpecListItem">Consumo extraurbano</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.consumption.extraUrban || '--'}</span> l/100km </p> 
                    </li>
                    <li>
                        <p className="DetailsSpecListItem">Consumo urbano</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.consumption.urban || '--'}</span>l/100km</p> 
                    </li>
                    <li>
                        <p className="DetailsSpecListItem">Consumo combinato</p> 
                        <p className="DetailsSpecListItemData">
                            <span >{props.item.consumption.combined || '--'}</span> l/100km</p>
                    </li>
                </ul>
            </div>
            
        </section>
    )
}

export default Emissions;