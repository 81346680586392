import React from 'react';
import './ResultsHeadbar.css';

const ResultsHeadbar = (props) => {
    return (

        <div className="Results-headbar">
            <div>
                <div className="Results-orderbySelector">
                    <select name="orderby" className="OrderBySelect" onChange={props.change} defaultValue="price">
                        <option value="price">Prezzo Crescente</option>
                        <option value="-price">Prezzo Decrescente</option>
                    </select>
                </div>
                <div className="Results-headbar-count">
                    <h4>
                        <span>{props.results === 100? props.results + '+' : props.results}</span> modelli trovati
                    </h4>
                </div>
                
            </div>
            <div className="Results-headbar-offers">
                <h3>Offerte interessanti correlate</h3>
                <div style={{padding: 2}}>
                    <section className="Offers-section" style={{position: 'relative'}}>
                        <div className="Offers-link" onClick={() => window.open('https://urcar.it/offerte','urcarOffers')} style={{cursor: 'pointer'}}>
                            <div className="Offers-title"><strong>{props.similarOffers}</strong> offerte simili in</div> 
                            <h6>noleggio</h6>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResultsHeadbar;