import React from 'react';
import { Redirect, withRouter} from 'react-router-dom';
import Api from './Modules/Api';
import store from './store/store';
import actions from './store/actions';
import './App.css';
import {connect} from 'react-redux';
import Page from './Components/Page';
import AnimatedIcon from './Components/AnimatedIcon/AnimatedIcon';

const mapStateToProps = (state, ownProps) => { 
  return {
      database: state.cars.database
  };
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
    this.api = new Api();
  }

  componentDidMount() {
    this.api.loadAll()
      .then(response => {
        console.log('alloffers',response)
        store.dispatch({type: actions.DATA_COMPLETED, payload: response });
        this.setState({loaded: true});
        this.api.getOffers().then(r => store.dispatch({type: actions.OFFERS_COMPLETED, payload: r}))
      })
      .catch(err => {
        console.error(err)
      });
  }
  
  validateModelsResponse(data) {
      return data.models? data.models : [];
  }

  render() {
    if (this.state.loaded) {
      return (<Redirect to='/marca' />);
    } else {
      return (
        <Page title="Caricamento">
            <div className="App">
                <h2>Caricamento in corso...</h2>
                <img src={'https://cdn.urcar.it/assets/urcar_logo.svg'} className="App-logo" alt="logo" />
                <AnimatedIcon />
            </div>
        </Page>
      )
    }
  }
}

export default connect(mapStateToProps)(withRouter(App));
//export default App;
