import React from 'react';

import './DetailsHeadbar.css';
import config from '../../Appconfig';


const DetailsHeadbar = (props) => {

    return (
        <div className="Details-headbar">
            <div className="Details-headbar-offers">
                {config.appTarget === 'admin' ? null : <h3>Scegli come ottenerla</h3>}
                <p className="subtitle"></p>
                <div className="Offers-sections" style={{padding: 2}}>
                    <section className="Offers-section" style={{position: 'relative'}}>
                        <img 
                            alt={(props.model && props.model.modelName) || ''} 
                            src={(props.model && props.model.icons[0].icon100) || null} 
                            style={{    
                                position: 'absolute',
                                left: 10,
                                top: 10,
                                width: 70
                            }} 
                        />
                        <div className="Offers-link" onClick={() => window.open('https://urcar.it/offerte','urcarOffers')} style={{cursor: 'pointer'}}>
                            <div className="Offers-title"><strong>{props.similarOffers}</strong> offerte simili in</div> 
                            <h6>noleggio</h6>
                        </div>
                    </section>

                    <section className="Offers-section" style={{position: 'relative'}}>
                        <img 
                            alt={(props.model && props.model.modelName) || ''} 
                            src={(props.model && props.model.icons[0].icon100) || null} 
                            style={{    
                                position: 'absolute',
                                left: 10,
                                top: 10,
                                width: 70
                            }} 
                        />
                        <div className="Offers-link" onClick={() => window.open('https://urcar.it/offerte','urcarOffers')} style={{cursor: 'pointer'}}>
                            <div className="Offers-title"><strong>{props.similarMakeOffers}</strong> offerte <strong>{props.model && props.model.makeName}</strong> in</div> 
                            <h6>noleggio</h6>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default DetailsHeadbar;