import React from 'react';
import Modal from 'react-modal';
import toMoney from '../../Modules/Utils';
import { Button } from '../FormItems';

const buttonInlineStyle = {fontSize: 12, width: 'auto', marginLeft: 10, padding: 5};
const buttonStyle = {fontSize: 12, width: 140, marginRight: 10};

const ModalOptionalsAlert = ({isOpen,onAfterOpen,style,contentLabel,title,itemlist,label,click,closeModal,acceptAllOptionalChange}) => {
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={closeModal}
            style={style}
            contentLabel={contentLabel}
        >
            <p>E' necessario effettuare delle modifiche agli accessori</p>
            <h4>{title}</h4>
            <div>
                <ul>
                    {
                        itemlist.sort((a,b) => a.description.localeCompare(b.description)).map(i => {
                            return (
                                <li key={i.idEquipment}>
                                    <span >{i.description}</span> 
                                    <span style={{marginLeft: 6}}>({toMoney(i.basePrice)})</span>
                                    {label &&
                                        <Button className="fucsia" 
                                            style={buttonInlineStyle} 
                                            label={label} 
                                            id={i.idEquipment} 
                                            click={click} 
                                        />
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            {!label && <Button className="fucsia" style={buttonStyle} label={title} name="doAction" click={acceptAllOptionalChange} />}
            <Button className="fucsia outline" style={buttonStyle} label="Chiudi" name="close" click={closeModal} />
        </Modal>
    );
};

export default ModalOptionalsAlert;