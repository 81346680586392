import React from 'react';
import Equipments from './Equipments';
import Engine from './Engine';
import Emissions from './Emissions';
import Api from '../../Modules/Api';
import Colours from './Colours';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import SidebarHeader from '../Sidebars/SidebarHeader';
import store from '../../store/store';
import actions from '../../store/actions';

const dividerStyle = {
    height: 20, 
    width: '50%', 
    borderBottom: 'thin solid rgb(245, 246, 248)'
}

class VersionDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebarToggle: {
                engine: false,
                equipments: true,
            },
            subtoggles: {},
            equipments: {}
        }
        this.toggle = this.toggle.bind(this);
        this.click = this.click.bind(this);
        this.api = new Api();
    }

    async componentDidMount(){
        if(this.props.version){
            //console.log('url','equip/' + this.props.version.versionId)
            /* const equipments = await this.api.get('equip/' + this.props.version.versionId)
                .then(response => {
                    return response.data;
                }); */
            let equipments;
            this.setState({loading: true});
            if(Object.values(this.props.equipments).length > 0) {
                equipments = this.props.equipments;
            } else {
                equipments = await this.api.get('equip/' + this.props.version.versionId).then(response => response.data);
                store.dispatch({
                    type: actions.VERSION_SELECTED,
                    payload: {...this.props.version,equipments}
                });
            }
            console.log("version equips",equipments)
            
            const subtoggles = {};
            for(const i in equipments){
                console.log('setting sub',i)
                subtoggles[i] = true;
            }
            for(const i in subtoggles) {
                subtoggles[i] = false;
                break;
            }
            this.setState({equipments, subtoggles, loading:false});
            const {hash} = window.location;
            const optionals = new Buffer(hash,'base64').toString('utf8').split('|');
            if(optionals) console.log('optionals',optionals)
            
            const foundOpts = [];
            for(const group in equipments) {
                if(group === 'makeName') continue;
                for(const i in equipments[group]){
                    const opt = equipments[group][i];
                    for(const o in opt){
                        if(!opt[o].manufacturerCode) continue;
                        if(optionals.includes(opt[o].manufacturerCode)){
                            console.log('find',opt[o])
                            foundOpts.push(opt[o]);
                        }
                    }
                }
            }
            console.log('found',foundOpts);
            if(this.props.initOptionals) {
                this.props.initOptionals(foundOpts)
            }
        }
    }
    
    toggle(e) {
        if(e.indexOf('sub:') > -1){
            const split = e.split(":")[1];
            this.setState(old => {
                old.subtoggles[split] = !old.subtoggles[split];
                return old;
            });
        } else {
            this.setState(old => {
                old.sidebarToggle[e] = !old.sidebarToggle[e];
                return old;
            });
        }
    }

    click(e) {
        const [type,id] = e.target.id.split("::")
        this.props.selectColor(id,type)
    }

    render(){
        return(
            <div className="VersionDetails-container" id="VersionDetails-container">
                <SidebarHeader toggle={() => this.toggle('engine')} toggled={this.state.sidebarToggle.engine} containerClass="VersionDetails-header">
                    <div style={{fontSize: 22, letterSpacing: -0.6}}>Motore e prestazioni</div>
                    <div>{this.state.sidebarToggle.engine? '' : <div style={dividerStyle}></div>}</div>
                </SidebarHeader>
                {
                    this.state.sidebarToggle.engine ? 
                        <Tabs>
                            <Tab title="Motore e prestazioni">
                                <Engine item={this.props.version} />
                            </Tab>
                            <Tab title="Emissioni e consumi">
                                <Emissions item={this.props.version} />
                            </Tab>
                        </Tabs>
                        : null
                }

                <div style={{height: 15}}></div>

                {
                    false && <Colours 
                        toggle={this.toggle} 
                        colors={this.props.colors} 
                        click={this.click}
                    />
                }

                <SidebarHeader toggle={() => this.toggle('equipments')} toggled={this.state.sidebarToggle.equipments} containerClass="VersionDetails-header">
                    <div style={{fontSize: 22, letterSpacing: -0.6}}>Accessori e Optional</div>
                    <div>{this.state.sidebarToggle.equipments? '' : <div style={dividerStyle}></div>}</div>
                </SidebarHeader>
                {
                    this.state.sidebarToggle.equipments ? 
                        <Tabs selectedIndex="Optional">
                            <Tab title="Equipaggiamenti di serie">
                                <Equipments 
                                    toggled={false}
                                    toggle={this.toggle} 
                                    click={this.props.click} 
                                    item={this.props.version} 
                                    equipments={this.props.equipments} 
                                    optionals={this.props.optionals} 
                                    change={this.props.change}
                                    showOptionals={false}
                                    subtoggles={this.state.subtoggles}
                                />
                            </Tab>
                            <Tab title="Optional">
                                <Equipments 
                                    toggled={false}
                                    toggle={this.toggle} 
                                    click={this.props.click} 
                                    item={this.props.version} 
                                    equipments={this.state.equipments} 
                                    optionals={this.props.optionals} 
                                    change={this.props.change}
                                    showOptionals={true}
                                    subtoggles={this.state.subtoggles}
                                    removeAllOptionals={this.props.removeAllOptionals}
                                />
                            </Tab>
                        </Tabs>
                        : null
                }
                
            </div>
        )
    }
}

export default VersionDetails;