import './Page.css';
import './Grids.css';
import React from 'react';

function Grid(props) {
    return (
        <div className={"Grid-container " + (props.className || 'defaultGrid')}>
            {props.children}
        </div>
    )
}

export default Grid;