import React from 'react';
import './Details.css';
import SidebarHeader from '../Sidebars/SidebarHeader';
import { getBgLightness } from '../../Modules/Utils'

const ColorTile = ({item, selected, click, type}) => {
    const forcolor = getBgLightness(item.baseColourHex) === 0 ? 'black' : 'white'
    return (
        <div onClick={click} key={item.id}  className={"VersionColorPreview" + (selected ? ' selected' : '')} id={`${type}::${item.id}`}>
            <button style={{backgroundColor: item.baseColourHex, border: 'thin solid #333', color: forcolor}}>
                
            </button>
            <span>{item.description} ({item.manufacturerCode})</span>
        </div>
    )
}

const Colours = ({colors,toggle,sidebarToggle, selected, click}) => {
    console.log('internal colors',colors)
    return (
        <section className="colors">
            <SidebarHeader toggle={toggle} toggled={sidebarToggle} containerClass="VersionDetails-header">
                <h3>Colori </h3>
                <div></div>
            </SidebarHeader>
            <div className="Colours-container">
            {
                Object.keys(colors).map( key => {
                    return (
                        <div className="EquipCategory" key={key}>
                            <div className="titolo-button" style={{backgroundColor: 'rgb(245, 246, 248)', height: 60}}>
                                <div className="h2-div subHeader">
                                    <h2>{key==='interior' ? 'Interni' : 'Esterni'}</h2>
                                </div> 
                                <button className="toggleselector prova" style={{backgroundColor: 'rgb(222, 37, 106) !important'}}>
                                    <i className="fas fa-chevron-down"></i>
                                </button>
                            </div>
                            <ul>
                                {
                                    colors[key].map(i => {
                                        return (
                                        <li className="EquipSubCategory" key={`${i.equipment.type}-${i.equipment.id}`}>
                                            
                                            <div style={{ padding: '5px 0 5px 16px' }}>
                                                <div style={{marginBottom: 5, fontWeight: 600}}>{i.equipment.description}</div>
                                                {
                                                    i.equipment.colours.map((j,k) => {
                                                        return (
                                                            <ColorTile 
                                                                key={k}
                                                                item={j} selected={selected === j.id || (j.id === 'STANDARD')} 
                                                                click={click}
                                                                type={key}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                })
            }
            </div>
        </section>
    )
}

export default Colours;