export const ParseEquipments = (conf,json) => {
    const decs = Object.keys(conf.alternativesAsEquipmentIdTree);
    console.log('ParseEquipments',decs,conf)

    let modalData;
    if(decs.length > 0){
        console.log('decision',decs[0],conf.alternatives.decision[decs[0]])
        const out = conf.alternatives.decision[decs[0]].map(i => {
            const itemToAdd = i.value || i.values[0];
            itemToAdd.idEquipment = parseInt(itemToAdd.id);
            itemToAdd.basePrice = itemToAdd.price;
            itemToAdd.type = "OPTION";

            delete itemToAdd.id;
            delete itemToAdd.price;
            delete itemToAdd.parents;
            return itemToAdd;
        });

        switch(decs[0]) {
            case "ADD_ALL":
                modalData = {
                    action: null,
                    title: 'Aggiungi tutti',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            case "REMOVE_ALL":
                modalData = {
                    action: null,
                    title: 'Rimuovi tutti',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            case "ADD_ONE_OF":
                modalData = {
                    action: 'Aggiungi',
                    title: 'Aggiungi uno tra',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            case "REMOVE_ONE_OF":
                modalData = {
                    action: 'Rimuovi',
                    title: 'Rimuovi uno tra',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            default: break;
        }
    }
    return modalData;
}