import React from 'react';
import Modal from 'react-modal';
import ModalRequestAutocard from '../../Components/ModalRequest/ModalRequestAutocard';
import ModalRequestOfferDetails from '../../Components/ModalRequest/ModalRequestOfferDetails';
import ModalRequestForm from '../../Components/ModalRequest/ModalRequestForm';
import Api from '../../Modules/Api';

class ModalRequest extends React.Component {
    constructor(props){
        super(props);
        const {model, selectedVersion, versionsLength, optionals, formData, formClick, formChange, sendForm, updateSub, confirms} = props;
        this.state = {
            model, 
            selectedVersion, 
            versionsLength, 
            optionals,
            formData, 
            formClick, 
            formChange, 
            sendForm, 
            updateSub, 
            confirms, 
            openChecks: false,
            comuni: [],
            showEquips: false
        }
        this.api = new Api();
    }
    componentDidMount() {
        /* this.api.loadComuni().then( resp => {
            this.setState({comuni: resp});
        }) */
        //console.log("COM INTERNO",this.props.comuni)
    }
    toggleEquips() {
        if(this.state.showEquips){
            this.setState({showEquips: false})
        } else {
            this.setState({showEquips: true})
        }
    }
    render(){
        const {
            model, 
            selectedVersion, 
            versionsLength, 
            optionals, 
            offers, 
            formData, 
            formClick, 
            formChange, 
            updateSub, 
            confirms, 
            updateChecks, 
            error, 
            closeError
        } = this.props;
        
        return (
            <div className="ModalContainer">
                <h2>La tua auto è configurata ed il tuo Personal car shopper ti invierà tutte le informazioni necessarie!</h2>
                <ModalRequestAutocard 
                    version={selectedVersion} 
                    model={model} 
                    optionals={optionals}
                    numVersions={versionsLength || 0}
                    showEquips={this.state.showEquips}
                    toggleEquips={this.toggleEquips.bind(this)}
                />
                <h2 style={{fontSize: 22, letterSpacing: -0.6, fontWeight: 600, textAlign: 'left'}}>Scegli come ottenerla alle migliori condizioni con <img src={'https://cdn.urcar.it/assets/urcar_logo.svg'} style={{height: 20, marginLeft: 4}} alt="Urcar!" /></h2>
                <ModalRequestOfferDetails 
                    model={model} 
                    offers={(offers && model && offers.filter(i => i.makeUrlCode === model.makeUrlCode)) || []} 
                    selectedOffers={updateSub}
                    quotes={formData.quotes}
                    suggestedUpdate={this.props.suggestedUpdate}
                />
                <div style={{height: 25}}></div>
                <ModalRequestForm 
                    formData={formData} 
                    change={formChange} 
                    click={formClick} 
                    checks={confirms} 
                    openChecks={() => this.setState({openChecks: !this.state.openChecks})}
                    showAll={this.state.openChecks}
                    updateChecks={updateChecks}
                    comuni={this.props.comuni}
                />
                <Modal
                    isOpen={(error? true : false) || false}
                    onRequestClose={closeError}
                    style={modalStyles}
                    contentLabel="Errore"
                >
                    <h4>Errore</h4>
                    {error && error.msg}
                </Modal>
                <div></div>
            </div>
        );
    }
};

const modalStyles = {
    content : {
      top                   : '30%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -30%)',
      paddingBottom         : 40,
      paddingLeft           : 40,
      paddingRight          : 40,
      paddingTop            : 20,
    }
};

export default ModalRequest;