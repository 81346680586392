import React, { Component } from 'react';

class SearchableSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            province: null,
            provinces: [],
            indexProvince: {},
            data: []
        }
        
    }
    componentDidMount() {
        console.log("Parsing", this.props.provincia, this.props.comune)
        this.parseProvince()
    }
    parseProvince() {
        const z = {};
        //console.log("DATA COMUNI",this.props.data)
        this.props.data.forEach((element,i) => {
            const prov = element.label.slice(element.label.length - 3).slice(0,2);
            if(!z[prov]) z[prov] = [];
            z[prov].push(element);
        });
        const p = Object.keys(z).sort();
        
        if (this.props.provincia) {
            this.setState({ filter: this.props.provincia, provinces: p, indexProvince: z, data: z[this.props.provincia].filter(i => i.label.match(new RegExp(this.props.provincia,'ig'))) });
        } else {
            this.setState({ provinces: p, indexProvince: z });
        }
    }
    search(e) {
        const {target} = e;
        this.props.change({target})
        const prov = target.value;
        const data = this.state.indexProvince[prov]? this.state.indexProvince[prov].filter(i => i.label.match(new RegExp(this.state.filter,'ig'))) : [];
        this.setState({province: prov, data})
    }
    change(e) {
        this.props.change(e)
    }
    render() {
        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 3fr',
                gridGap: 5
            }}>
                {/* <input value={this.state.filter} onChange={(e) => this.setState({filter: e.target.value})} placeholder={this.props.placeholder || "Filtra"} /> */}
                <div style={{width: '100%'}}>
                    <label>Provincia</label>
                    <select name="provincia" onChange={this.search.bind(this)} value={this.props.provincia}>
                        <option>Seleziona</option>
                        {
                            this.state.provinces.map(i => <option key={i} value={i}>{i}</option>)
                        }
                    </select>
                </div>
                <div style={{width: '100%'}}>
                    <label>Comune*</label>
                    <select name="comune" onChange={this.change.bind(this)} value={this.props.comune}>
                        <option>Seleziona</option>
                        {
                            (this.state.data || [])
                                .sort((a,b) => a.label.localeCompare(b.label))
                                .map(i => <option key={i.value} value={i.value}>{i.label}</option>)
                        }
                    </select>
                </div>
            </div>
        );
    }
}

export default SearchableSelect;