import React from 'react';
import {toMoney} from '../../Modules/Utils';

import '../CarModel/CarModel.css';
import '../FormItems/FormItems.css';


const Separate = () => (<span style={{color: '#DE256A', display: 'inline-block', marginLeft: 5, marginRight: 5}}>|</span>);

const ModalRequestAutocard = ({model,version,optionals,numVersions,showEquips, toggleEquips}) => {
    let equips = [];
    if(version && version.equipments){
        for(const sect of Object.keys(version.equipments)){
            for(const sub of Object.keys(version.equipments[sect])){
                const items = version.equipments[sect][sub];
                equips = equips.concat(items.filter(e => e.type==='STANDARD').map(e => e.description))
            }
        }
    }

    const optionalPrice = (optionals && optionals.length)? optionals.reduce((i,j) => i + j.basePrice,0) : 0;
    const modelprice = version? version.price : model ? model.minPrice : 0;
    return (
        <div className="ModalRequest-container AutoCard">
            <div style={{boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)',borderRadius: 4, padding: 20}}>
                <div style={{display: 'grid', gridTemplateColumns: '2fr 3fr',gridGap: 16,}}>
                    <div>
                        <div>
                            <img src={model.icons[0].icon200} alt="carImage" style={{width: 140}} />
                        </div>
                        <h3 style={{ fontSize: 20, fontWeight: 600, margin: 0}}>{model.makeName} {model.submodelCommercialName}</h3>
                        
                        {version && <h4 style={{ fontSize: 20, fontWeight: 600, color: '#DE256A', marginTop: 0, marginBottom: 10, lineHeight: 1}}>{version.versionName}</h4>}
                        <p className="extras">Prezzo di listino{ optionals && optionals.length ? ' con accessori selezionati' : ''}</p>
                        <p className="price" style={{fontSize: 25}}>{toMoney(modelprice + optionalPrice)}</p>
                        {optionals && optionals.length ? <p className="extras">optional: {toMoney(optionalPrice)}</p> : null}
                    </div>
                    <div className="ModalRequest">
                        <h3 style={{margin: 0,fontSize: 18, letterSpacing: -0.57, fontWeight: 600}}>{
                            version ?
                                <div>
                                    {version.calculatedFuelType.charAt(0).toUpperCase() + version.calculatedFuelType.slice(1)}
                                    <Separate/>{version.engine.HP} CV 
                                    <Separate/>Trazione {version.traction} 
                                    <Separate/>Cambio {version.gearboxType}
                                </div>
                                :
                                'Il prodotto è disponibile in:'
                        }</h3>
                        {!version && <h4 style={{margin: 0,fontSize: 16, letterSpacing: -0.33, fontWeight: 600}}>{numVersions} versioni</h4>}
                        {/* color: #333333;	font-family: "Poppins Light";	font-size: 14px;	letter-spacing: -0.29px;	line-height: 20px; */}
                        {
                            !version ? 
                                <p style={{fontSize: 14, letterSpacing: -0.29}}>Potrai scegliere la versione ed i dettagli in un momento successivo, confrontandoti con il tuo PCS ed in base alle tue esigenze!</p>
                                :
                                <div>
                                    <p style={{color: '#989898', fontSize: 12}}><strong style={{color: '#262D3F'}}>Equipaggiamenti di serie: 
                                    <button style={{display:'inline-block', marginLeft: 10, color: '#DE256A', fontSize: 11}} id="equipShowBut" onClick={toggleEquips}>{showEquips? 'Nascondi':`Mostra (${equips.length})`}</button></strong> 
                                    {showEquips? <span style={{'display':'block'}}>{equips.join(', ')}</span> : null}
                                    </p>
                                    {   optionals && optionals.length > 0 ? 
                                            <p style={{color: '#989898', fontSize: 12}}><strong style={{color: '#262D3F'}}>Optional selezionati:</strong> {optionals.map(i => `${i.description} (${toMoney(i.basePrice)})`).join(', ')}</p>
                                            :
                                            <p style={{color: '#989898', fontSize: 12}}>Nessun optional selezionato</p>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalRequestAutocard;