import Actions from '../actions';

const initialState = {
    loading: false,
    error: null,
    make: null,
    bodyType: null,
    database: [],
    price: {
        min: null,
        max: null
    }
  };
  
export default function carsReducer(state = initialState, action) {
    //console.log("itemsReducer", state, action)
    switch(action.type) {
        case Actions.START_TRIGGER_MAKE:
            console.log("Reducer move make", action);
            return {
                ...state,
                make: [action.make]
            }
        case Actions.START_TRIGGER_BODY:
                console.log("Reducer move body", action);
            return {
                ...state,
                bodyType: [action.bodyType]
            }
        case Actions.ADD_MAKE:
                console.log("Reducer ADD make", action);
                return {
                    ...state,
                    make: state.make ? state.make.concat([action.payload.id]) : [action.payload.id]
                }
        case Actions.ADD_BODY:
                console.log("Reducer ADD bodyType", action);
                return {
                    ...state,
                    bodyType: state.bodyType ? state.bodyType.concat([action.payload.id]) : [action.payload.id]
                }
        case Actions.DATA_COMPLETED:
                return {
                    ...state,
                    database: action.payload
                }
        default: break;
    }
    return state;
}