import React from 'react';
import Modal from 'react-modal';
import { Button } from '../FormItems';


const ModalRequestThankyou = ({isOpen,closeModal,style,contentLabel}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={style}
            contentLabel={contentLabel}
            
        >
            <div style={{
                background: 'url(/thankyou-bg.png) top center no-repeat',
                minHeight: 400,
                backgroundSize: 'contain',
                textAlign: 'center',
                paddingTop: 250
            }}>
                <h2 style={{
                    fontSize:30,
                    maxWidth: 480,
                    color: '#262D3F',
                    margin: '0 auto',
                    letterSpacing: -0.65,
                    lineHeight: '38px'
                }}>Complimenti! La tua richiesta è stata inviata al tuo PCS!</h2>
                <p style={{
                    width: 502,	
                    opacity: 0.5,	
                    color: '#333333',
                    fontSize: 18, 
                    letterSpacing: -0.38,
                    lineHeight: '27px',	
                    textAlign: 'center', 
                    margin:'20px auto 0'
                }}>Riceverai una mail con le offerte e le credenziali per entrare nel portale, nel frattempo verrai contattato dal PCS che ti spiegherà i prossimi passaggi!</p>
                <Button className="fucsia" click={closeModal} label="Chiudi" />
            </div>
            
        </Modal>
    );
};

export default ModalRequestThankyou;