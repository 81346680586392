import React from 'react';
import {toMoney} from '../../Modules/Utils';
import Lightbox from 'react-image-lightbox';
import './CarModel.css';
import 'react-image-lightbox/style.css';
import Skeleton from 'react-loading-skeleton';
import { Button } from '../FormItems';
import config from '../../Appconfig';


class CarModelWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false
        }
    }
    
    render(){
        const { photoIndex, isOpen } = this.state;
        if(this.props.selectedModel){
            let {model, summary} = this.props.selectedModel;

            const optionalList = (this.props.optionals && this.props.optionals.length)? this.props.optionals.map(i => i.description).join(', ') : null;
            const optionalPrice = (this.props.optionals && this.props.optionals.length)? this.props.optionals.reduce((i,j) => i + j.basePrice,0) : 0;
            const modelPrice = model ? this.props.selectedVersion ? this.props.selectedVersion.price : model.minPrice : null;

            return (
                <div className="WidgetModel-container" style={{marginTop: this.props.marginTop}}>
                    <div className="WidgetModel-content">
                        <div className="AutoCard">
                            <header>
                                <div className="GridHeaderCard">
                                    {
                                        model? 
                                            <img src={model.makeLogo} className="details-logo" alt={model ? model.makeName : 'logo'} />
                                            : 
                                            <Skeleton circle={true} height={90} width={90} />
                                    }
                                    
                                    <div>
                                        <div className="makeName">{model ? model.makeName : <Skeleton />}</div>
                                        <h2 style={{marginBottom: 0}}>{model ? model.submodelCommercialName : <Skeleton />}</h2> 
                                        {
                                            model? 
                                            <h3 style={{marginTop: 0, fontWeight: 600}}>{this.props.selectedVersion? this.props.selectedVersion.versionName : null}</h3>
                                            :
                                            <Skeleton />
                                        }
                                    </div>
                                </div>
                            </header>
                            

                            <div className="AutoDetails" style={{marginTop: 20}}>
                                <p className="price">{summary ? toMoney(modelPrice + optionalPrice) : <Skeleton width={200} />}</p> 
                                { 
                                    this.props.optionals && this.props.optionals.length > 0 ?
                                        <div>
                                        <em className="price" style={{fontSize: 15, fontWeight: 600}}>
                                            Optional: {toMoney(optionalPrice)}
                                        </em>
                                        <p className="subtitle">{optionalList}</p>
                                        </div> 
                                        : null
                                }
                                {/* <p className="extras">{model? "Prezzo di listino" : <Skeleton width={200} />}</p> */}
                                <p className="subtitle" style={{color: 'var(--primaryColor)',marginTop: this.props.optionals && this.props.optionals.length > 0 ? 10 : 0}}>{model ? "Prezzo di listino" + (this.props.optionals && this.props.optionals.length > 0 ? ' con accessori selezionati' : '') : <Skeleton width={200} />}</p>
                            </div>
                            
                            {isOpen && model && model.images && (
                                <Lightbox
                                    mainSrc={model.images[photoIndex].image800}
                                    nextSrc={model.images[(photoIndex + 1) % model.images.length].image800}
                                    prevSrc={model.images[(photoIndex + model.images.length - 1) % model.images.length].image800}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + model.images.length - 1) % model.images.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % model.images.length,
                                        })
                                    }
                                />
                            )}

                            <div className="WidgetModel-gallery">
                                {
                                    model ? 
                                        <img src={model.images ? model.images[0].image400 : null} className="mainImage" alt="CarImage" style={{cursor: 'pointer'}} onClick={()=>this.setState({ isOpen: true, photoIndex: 0 })} /> 
                                        :
                                        <Skeleton height={250} />
                                }
                                <ul>
                                    {
                                        model && model.images ?
                                            model.images.slice(1,4).map((item,key) => {
                                                return (
                                                    <li key={'img-' + (key+1)}>
                                                        <button onClick={()=>this.setState({ isOpen: true, photoIndex: key+1 })}>
                                                            <img src={item.image200} className="subImage" alt="CarImage" /> 
                                                        </button>
                                                    </li>
                                                )
                                            })
                                            :
                                            [1,2,3].map(i => {
                                                return(
                                                    <button key={i} onClick={()=>{}}>
                                                        <img src="https://cdn.urcar.it/assets/load_more_images@2x.png" className="subImage" alt="CarImage" /> 
                                                    </button>
                                                )
                                            })
                                    }
                                    <li>
                                        <button onClick={()=>this.setState({ isOpen: true })}>
                                            <img src="https://cdn.urcar.it/assets/load_more_images@2x.png" className="subImage" alt="CarImage" /> 
                                        </button>
                                    </li>

                                </ul>
                            </div>
                            {this.props.selectedVersion ? 
                            <Button 
                                label={config.appTarget === 'admin' ? "Seleziona auto" : "Richiedila ora!"} 
                                click={this.props.openRequest} 
                                className="fucsia" 
                                style={{width: '100%', height: 60, fontSize: 20, fontWeight: 700}}
                            /> : null}  
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="WidgetModel-container">
                    <div className="WidgetModel-content">
                        <div className="AutoCard">
                            Loading...
                        </div>
                    </div>
                </div>
            );
        }
        
    }

}



export default CarModelWidget;

