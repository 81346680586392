export const START_TRIGGER_MAKE = "START_TRIGGER_MAKE";
export const START_TRIGGER_BODY = "START_TRIGGER_BODY";
export const START_TRIGGER_PRICE = "START_TRIGGER_PRICE";
export const ADD_MAKE = "ADD_MAKE";
export const ADD_BODY = "ADD_BODY";
export const ADD_PRICE = "ADD_PRICE";
export const MODEL_SELECTED = "MODEL_SELECTED";
export const FILTER_CHANGED = "FILTER_CHANGED";
export const VERSION_SELECTED = "VERSION_SELECTED";
export const OPTIONALS_CHANGED = "OPTIONALS_CHANGED";
export const COLOR_CHANGED = "COLOR_CHANGED";
export const DATA_COMPLETED = "DATA_COMPLETED";
export const VERSIONS_COMPLETED = "VERSIONS_COMPLETED";
export const OFFERS_COMPLETED = "OFFERS_COMPLETED";



export const startTriggerMake = (make) => {
    return { type: START_TRIGGER_MAKE, make }
};

export const startTriggerBody = (bodyType) => {
    return { type: START_TRIGGER_BODY, bodyType }
};

export default {
    START_TRIGGER_MAKE,
    START_TRIGGER_BODY,
    START_TRIGGER_PRICE,
    ADD_MAKE,
    ADD_BODY,
    ADD_PRICE,
    MODEL_SELECTED,
    FILTER_CHANGED,
    VERSION_SELECTED,
    OPTIONALS_CHANGED,
    COLOR_CHANGED,
    DATA_COMPLETED,
    VERSIONS_COMPLETED,
    OFFERS_COMPLETED,
    startTriggerMake,
    startTriggerBody
}