import React from 'react';
import './Details.css';
import { Grid } from '../Page';
import '../Page/Grids.css';
import { Button } from '../FormItems';
import toMoney from '../../Modules/Utils';
import { Link } from 'react-router-dom';

const VersionWidget = (props) => {
    const buttonStyle = {fontSize: 12, width: 140, marginRight: 10};
    return (
        <div className={"VersionWidget "}>
            <div className="floating">{props.item.trimName !== '-'? props.item.trimName : 'Base'}</div>
            <h3>{props.item.versionName}</h3>
            <Grid className="versionListGrid">
                <div>
                    <h4>Alimentazione</h4>
                    <div className="propValue">{props.item.calculatedFuelType}</div>
                </div>
                <div>
                    <h4>Potenza</h4>
                    <div className="propValue">{props.item.engine.HP} cv ({props.item.engine.kw} kw)</div>
                </div>
                <div>
                    <h4>Trazione</h4>
                    <div className="propValue">{props.item.traction}</div>
                </div>
                <div>
                    <h4>Cambio</h4>
                    <div className="propValue">{props.item.gearboxType} <br /> {props.item.transmissionNumGears} marce</div>
                </div>
                <div>
                    <h4>Motore</h4>
                    <div className="propValue">{props.item.engine.cm3} cm3 <br /> {props.item.engine.maxSpeed} km/h</div>
                </div>
            </Grid>
            <Button className="fucsia" style={buttonStyle} label="Richiedi" name="select" click={props.selectVersion} id={props.item.versionUrlCode} /> 
            <Link to={"/versioni/" + props.link + "/" + props.item.versionUrlCode}>
                <Button className="fucsia outline" style={buttonStyle} name="optionals" label="Configura accessori"  id={props.item.versionUrlCode}  click={props.configure} />
            </Link>
            <div className="price">{toMoney(props.item.price)}</div>
        </div>
    )
}

export default VersionWidget;