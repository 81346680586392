import React from 'react';
import './FormItems.css';



 export const Button = (props) => {
    return (
        <button 
            id={props.id || Math.round(Math.random()*1283910230183 + 1273723)}
            style={props.style}
            className={props.className} 
            onClick={props.click || (e => console.log('placeholder',e)) }>
            {props.label || 'Button'}
        </button>
    );
}

export default {Button};