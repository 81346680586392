import App from './App';
import React from 'react';
import {Login,BodyType,Make,ModelResults,ModelDetails} from './Containers';


const modelRenderer  = (props) => {
    return <ModelResults {...props} />
}

const versionRenderer  = (props) => {
    return <ModelDetails {...props} />
}

const routes = [
    {
        path: '/',
        exact: true,
        component: App
    },
    {
        path: '/login',
        exact: true,
        component: Login
    },
    {
        path: '/marca',
        exact: true,
        component: Make
    },
    {
        path: '/tipo',
        exact: true,
        component: BodyType
    },
    {
        path: '/modelli',
        exact: true,
        render: modelRenderer
    },
    {
        path: '/modelli/:searchString',
        render: modelRenderer
    },
    {
        path: '/versioni/:make',
        render: modelRenderer,
        exact: true,
    },
    {
        path: '/versioni/:make/:model/:version?',
        render: versionRenderer
    },
]


export default routes;