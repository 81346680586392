import React from 'react';
import { CustomCheckbox } from '../ModalRequest/ModalRequestOfferDetails';

const MiniOffer = ({ name, off, toggle, car, type, icon, selected, version }) => {
    return (
        <div className="MiniOffer-item"
            style={{ cursor: 'pointer' }}
            onClick={toggle}
            key={off.id}
            id={off.id}
            data-tip={off.nome}
            title={off.nome}
            >
            
            <div className="gradient" style={{pointerEvents: 'none'}}></div>
            <CustomCheckbox selected={selected} name={type} />
            <div className="leftSide">
                <img className="noselect miniCarIcon" src={icon} alt={type} style={{ left: 27, width: 25, height: 25, bottom: 18 }} />
                <img className="noselect miniCarImg" src={car} alt={type} style={{ marginTop: 10, position: 'absolute', left: 33, width: 75 }} />
                <div>{off.km && off.km + ' km'}</div>
            </div>
            
            <div style={{pointerEvents: 'none'}} className="offersData" title={off.nome}>
                <h4>{name}</h4>
                <span className="MiniOfferVersion">{version}</span>
            </div>
            <div style={{pointerEvents: 'none'}} className="offersPrice">
                <p style={{ textAlign: 'center', lineHeight: 1 }}>{off.fee || parseInt(off.fee) === 0 ? 'fee: ' + off.fee + '€' : ''} </p>
                <h4>{off.canone} &euro;</h4>
                <p style={{ textAlign: 'center', lineHeight: 0.75 }}>al mese i.e.</p>
                <p style={{ textAlign: 'center', margin: 0 }}>{off.durata && off.durata + ' mesi'}</p>
            </div>
        </div>
    )
}

export default MiniOffer;