import React from 'react';
import './Sidebars.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

const SidebarHeader = (props) => {
    return (
        <header className={props.containerClass || "Sidebar-header"}>
            {props.children}
            {!props.notoggle && <button className="sidebarToggle" onClick={props.toggle}>
                <FontAwesomeIcon icon={!props.toggled ? faCaretUp : faCaretDown} />
            </button>}
        </header>
    )
}

export default SidebarHeader;