import React, { Component } from 'react';
import './Tabs.css';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex : null,
            parsedChildren : []
        }
    }
    
    componentDidMount() {
        let index;
        const parsedChildren = React.Children.map(this.props.children,
            i => {
                //console.log('type name',i.type.displayName, i.props.title)
                //console.log(i)
                if(i.type.displayName === 'Tab') {
                    if(!index) index = i.props.title;
                    return {name: i.props.title}
                }
            }
        )
        //console.log(this.props.children,parsedChildren)
        if(this.props.selectedIndex) index = this.props.selectedIndex;
        this.setState({parsedChildren, selectedIndex: index})
        //console.log('selecting',index)
    }

    selectTab = (e) => {
        this.setState({selectedIndex: e.target.id})
        if(this.props.onChangeTab && typeof this.props.onChangeTab == 'function') {
            this.props.onChangeTab(e.target.id);
        }
    }

    filterChildren = () => {
        return this.props.children.find(i => i.props.title === this.state.selectedIndex)
    }

    filterButtons = () => {
        return this.state.parsedChildren.map(i => {
            return <TabLink 
                key={i.name}
                name={i.name}
                onClick={this.selectTab}
                selected={this.state.selectedIndex===i.name}
            />
        })
    }

    render() {
        return (
            <div className="tabsContainer">
                <div className='tabLinksContainer'>
                    {this.filterButtons()}
                </div>
                <br style={{clear: 'both'}} />
                {this.filterChildren()}
            </div>
        );
    }
}

export const TabLink = ({name, onClick, selected}) => {
    return (
        <div key={name} className={selected? "tabLinkItem selected":"tabLinkItem"} id={'tab-' + name}>
            <button onClick={onClick} id={name}>{name}</button>
        </div>
    )
}



export default Tabs;