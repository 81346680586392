import React from 'react';
import './Details.css';
import Skeleton from 'react-loading-skeleton';

const Dimensions = (props) => {
    return (
        <div className="dettaglioProdotto">
            <div className="boxAuto">
                <div className="imgHolder">
                    {
                        props && props.icon ?
                            <img alt="preview" src={props.icon || null} className="img-models" style={{ width: 400, minHeight: 200 }} />
                            :
                            <Skeleton height={50} />
                    }
                </div>
                {
                    props && props.item && (
                        <div>
                            <div className="grid-container" style={{ marginBottom: 40 }}>
                                <div className="grid-item bgwhite">
                                    <div>
                                        <img alt="width" src="https://cdn.urcar.it/assets/altezza.png" className="dimIcons" /> Altezza
                                    </div>
                                    <div className="dimSize">{props.item.height} cm</div>
                                </div>
                                <div className="grid-item bgwhite">
                                    <div>
                                        <img alt="height" src="https://cdn.urcar.it/assets/altezza.png" className="dimIcons rotate" /> Larghezza
                                    </div>
                                    <div className="dimSize">{props.item.width} cm</div>
                                </div>
                                <div className="grid-item bgwhite">
                                    <div>
                                        <img alt="length" src="https://cdn.urcar.it/assets/altezza.png" className="dimIcons rotate" /> Lunghezza
                                    </div>
                                    <div className="dimSize">{props.item.length} cm</div>
                                </div>
                            </div>
                            <div className="grid-container" style={{ gridTemplateColumns: '1fr 1fr', marginBottom: 40 }} >
                                <div className="grid-item bgwhite capacityDetails" >
                                    <div> Posti <span>{props.item.seats}</span></div>
                                </div>
                                <div className="grid-item bgwhite capacityDetails alt">
                                    <div>Bagagliaio <span>{props.item.cargoCapacity} l</span></div>
                                </div>
                            </div>
                        </div>)
                }
            </div>
        </div>
    
    )
}

export default Dimensions;