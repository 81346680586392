import './Selectors.css';
import React, {Component} from 'react';
import {bodyTypes} from '../../assets/data';
import SidebarHeader from '../Sidebars/SidebarHeader';

const BodyTypeSelectorItem = (props) => {
    const selClass = props.selected ? 'selected' : '';
    return (
        <div className={"BodyTypeSelector-item " + selClass} onClick={props.click} id={props.id}>
            <div className="icona">
                <img src={props.img} alt={props.bodyType} title={props.bodyType} />
            </div>
            {props.label}
        </div>
    );
}

class BodyTypeSelector extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            sidebarToggle: true,
        }

        this.toggleItem = this.toggleItem.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggleItem = (e) => {
        const it  = e.target.attributes.name.value;
        console.log("toggle", it, this.props);
        this.props.selected(it);
    }

    toggle() {
        console.log(this.state.sidebarToggle ? 'show': 'hide')
        this.setState({sidebarToggle: !this.state.sidebarToggle});
    }
    
    render () {
        const bodyItems = [];
        const {sidebar} = this.props; 
        for(const i in bodyTypes){
            const icon = `https://cdn.urcar.it/assets/${bodyTypes[i].bodyTypeUrlCode}.svg`;
            //const icon = `https://cdn.urcar.it/assets/${bodyTypes[i].bodyTypeUrlCode}.png`;
            const item = <BodyTypeSelectorItem 
                    selected={(this.props.filter).indexOf(bodyTypes[i].bodyTypeUrlCode)>-1} 
                    key={bodyTypes[i].bodyTypeUrlCode} 
                    id={bodyTypes[i].bodyTypeUrlCode} 
                    img={icon} 
                    label={bodyTypes[i].bodyType} 
                    click={this.props.click} 
                /> ;
            bodyItems.push(item);
        }
    
        return (
            <div className={"BodyType-selectorContainer"}>
                {
                    this.props.sidebar ? 
                        <SidebarHeader toggle={this.toggle} toggled={this.state.sidebarToggle} containerClass="VersionDetails-header">
                            Tipologia 
                            <div>
                            {
                                this.props.filter.map(i => <small key={i}>{i}</small>)
                            }
                            </div>
                        </SidebarHeader>
                        :
                        null
                }
                
                    
                <div className={"BodyType-selector " + (sidebar ? 'sidebar ' : ' ') + (this.props.sidebar && this.state.sidebarToggle ? 'toggled' : '')}>
                    {bodyItems}
                </div>
                
                
            </div>
        );
    }
}

export default BodyTypeSelector;