import './Page.css';
import React from 'react';
import toMoney from '../../Modules/Utils';
import { Button } from '../FormItems';
import config from '../../Appconfig';

function Header({model,optionals,selectedVersion, openRequest}) {
    let modelPrice = 0;
    const optionalPrice = (optionals && optionals.length)? optionals.reduce((i,j) => i + j.basePrice,0) : 0;
    if(model) {
      modelPrice = selectedVersion ? selectedVersion.price : model.minPrice;
    }
    return (
        <header className="App-header">
          <img src={'https://cdn.urcar.it/assets/urcar_logo.svg'} className="App-logo" alt="logo" onClick={() => window.open(config.appTarget === 'admin' ? '/modelli' : 'https://urcar.it', config.appTarget === 'admin' ? '_self' : '_blank')} />
          {
             model ? 
             <div className="HeaderCarData">
                {openRequest && selectedVersion ? <Button className="fucsia" label={config.appTarget === 'admin' ? "Seleziona auto" : "Richiedila ora!"} style={{marginTop: 0, padding: 5, width: 140}} click={openRequest} id={selectedVersion.versionUrlCode} /> : <div></div>}
                <img src={model.makeLogo} className="details-logo" alt={model.makeName} />
                <div>
                  <h3>{model.makeName} {model.submodelCommercialName}</h3>
                  {selectedVersion ? <div className="selectedVersion">{selectedVersion.versionName}</div> : <div style={{height: 10}}></div>}
                  <p className="price">{toMoney(modelPrice + optionalPrice)}</p>
                </div>
              </div> : null
          }
        </header>
    )
}

export default Header;